import React, { useState, useEffect } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import { getUserDetails } from '../actions/userActions'
import Message from '../components/Message'
import { addToCart, removeFromCart, saveShippingAddress } from '../actions/cartActions'
import { formatCurrency } from "react-native-format-currency"
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import HeaderClean from '../components/HeaderClean';
import Footer from '../components/Footer';
import '../styles/shipping.css';

function ShippingScreen() {

    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart


    const match = useParams()
    const Location = useLocation()
    const navigate = useNavigate();

    const dispatch = useDispatch()


    const [direccion, setDireccion] = useState(shippingAddress.direccion)
    const [ciudad, setCiudad] = useState(shippingAddress.ciudad)
    const [barrio, setBarrio] = useState(shippingAddress.barrio)
    const [localidad, setLocalidad] = useState(shippingAddress.localidad)
    const [celular, setCelular] = useState(shippingAddress.celular)

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userLogin)
    const { success } = userUpdateProfile

    /*
        const userAllLogin = useSelector(state => state.userAllLogin)
        const { userInfoAll} = userAllLogin 
     */
    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            if (!user || !user.name || success) {
                dispatch(getUserDetails('profile'))

            } else {
                const saved = localStorage.getItem("userInfoAll");
                const initialValue = JSON.parse(saved);
                setCiudad(initialValue[0].ciudad)
                setDireccion(initialValue[0].direccion)
                setLocalidad(initialValue[0].localidad)
                setBarrio(initialValue[0].barrio)
                setCelular(initialValue[0].celular)

            }
        }
    }, [navigate, userInfo, dispatch, user, success])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({ ciudad, direccion, localidad, barrio, celular }))
        navigate(`/payment`)
    }


    return (
        <>
            <HeaderClean />
            <div className='shipping_container'>
                <FormContainer>
                    <h2>Confirmación de datos de envió</h2>
                    <CheckoutSteps step1 step2 />
                    <Form onSubmit={submitHandler}>

                        <Form.Group controlId='ciudad'>
                            <Form.Label>🌆 Ciudad</Form.Label>
                            <Form.Control
                                required
                                type='ciudad'
                                placeholder='Ingresa tu ciudad'
                                value={ciudad}
                                onChange={(e) => setCiudad(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='direccion'>
                            <Form.Label>🏢 Dirección</Form.Label>
                            <Form.Control
                                required
                                type='direccion'
                                placeholder='Ingresa tu direccion'
                                value={direccion}
                                onChange={(e) => setDireccion(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='localidad'>
                            <Form.Label> 🏛 Localidad</Form.Label>
                            <Form.Control
                                type='localidad'
                                placeholder='Ingresa tu localidad'
                                value={localidad}
                                onChange={(e) => setLocalidad(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='barrio'>
                            <Form.Label>🏬 Barrio</Form.Label>
                            <Form.Control
                                type='barrio'
                                placeholder='Ingresa tu barrio'
                                value={barrio}
                                onChange={(e) => setBarrio(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='celular'>
                            <Form.Label>📱 Número de celular</Form.Label>
                            <Form.Control
                                required
                                type='number'
                                placeholder='Ingresa tu número de celular'
                                value={celular}
                                onChange={(e) => setCelular(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary'>
                            Continuar
                        </Button>
                    </Form>
                </FormContainer>
            </div>
            <Footer />
        </>
    )
}

export default ShippingScreen
