import React, { useState } from "react";
import HeaderClean from "../components/HeaderClean";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import '../styles/facilitator.css';

function PasswordResetEmail(){
    //https://backend.tucolonia.co/api/users/accounts/password_reset/
    const [email_reset, setEmailReset] = useState("")

    const isValidEmail = () => {
        return /\S+@\S+\.\S+/.test(email_reset);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isValidEmail()) {
            document.getElementById("error_email_reset").style.display = "block"
            document.getElementsByName('email_reset')[0].classList.add("error")
            return
        }

        document.getElementById("error_email_reset").style.display = "none"
        document.getElementsByName('email_reset')[0].classList.remove("error")
        
        console.log("emailR: ", email_reset);
    }

    const handleChange = (e) => {
        setEmailReset(e.target.value)
        document.getElementById("error_email_reset").style.display = "none"
        document.getElementsByName('email_reset')[0].classList.remove("error")
    }

    return(
        <>
            <HeaderClean />
            <div className='prod_portada'>
                <h3>Recuperar contraseña</h3>
            </div>
            <Form onSubmit={handleSubmit} className="password_reset_container_form">
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Correo Electronico *">
                            <Form.Control type="email" placeholder="Correo Electronico *" name='email_reset' value={email_reset} onChange={handleChange} />
                            <small id='error_email_reset' className='form_error_input'>Por favor ingrese un email valido</small>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className='g-2'>
                    <Col md>
                        <Button type="submit">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PasswordResetEmail