import React from "react";
import nosotros from '../images/nosotros.png';
import '../styles/us.css';

function Us(){
    return(
        <div className="nosotros_container" id="como_funciona">
            <div className="texto_container">
                <h1>Así hacemos compras inteligentes</h1>
                <p>Primer plataforma de compras colectivas inteligentes de latinoamerica</p>
                <iframe src="https://www.youtube.com/embed/uMqmdF-FmGw" title="Tu Colonia Compras Colectivas Inteligentes. Cómo funciona?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="imagen_container">
                <img src={nosotros} />
            </div>
        </div>
    );
}

export default Us;