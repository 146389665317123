import React from 'react';
import SliderHome from './SliderHome';
import Collage from './Collage';
import Us from './Us';
import Steps from './Steps';
import Promotions from './Promotions';
import CardSections from './CardSections';
import Header from './Header';
import Footer from './Footer';

function Home(){
    return(
        <div>
            <Header />
                <SliderHome />
                <Collage />
                <Us />
                <Steps />
                <Promotions />
                <CardSections />
            <Footer />
        </div>
    );
}

export default Home;