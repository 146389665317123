import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { listProductsByName } from "../actions/productActions";
import { listCategories } from '../actions/categoryActions';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Loader from '../components/Loader'
import Message from '../components/Message'
import ProgressBar from '../components/ProgressBarf';
import Rating from '../components/Rating'
import { formatCurrency } from "react-native-format-currency"
import ProductRender from './ProductRenderScreen';
function ProductResult() {
    const params = useParams()
    const name = params.name
    const namesReplace = {
        "Hogar (Alimentos, Víveres, Aseo)": "Hogar",
        "Autos y Motos (Servicios y Repuestos)": "Autos y Motos",
        "Bicicletas (Servicios y Accesorios)": "Bicicletas",
        "Productos y Servicios para tu mascota": "Productos y Servicios para tu mascota",
        "Otros (Vestuario, libros, seguros, viajes, reparaciones)": "Otros",
        "Equipos y enseres (electrodomésticos, muebles, accesorios)": "Equipos y Enseres"
    }

    //Se obtienen las categorias
    const dispatchCategories = useDispatch()
    const categoryList = useSelector(state => state.categoryList)
    const { categories } = categoryList
    categories.sort((a, b) => (a.category_name > b.category_name) ? 1 : -1)
    
    

    //Se obtienen los productos segun el nombre a filtrar
    const dispatchProductByName = useDispatch()
    const productCategoryList = useSelector(state => state.productCategoryList)
    const { loading, error, products } = productCategoryList
    useEffect(() => {
        dispatchCategories(listCategories())
        dispatchProductByName(listProductsByName(name))
    }, [dispatchCategories, dispatchProductByName])

    

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h1>Resultado de busqueda: {name}</h1>
            </div>
            <div className='prod_container'>
                <div className='filters'>
                    <div className='filter_cat filter_box'>
                        <h4 className='filter_title'>Categorias</h4>
                        <div className='filter_container'>
                            <Link to='/products/' className='link_cat' id='cat_0'>Todos</Link>
                            {
                                 categories.map(categoria => (                                    
                                    <Link to={`/products/${categoria.id_category}`} className='link_cat' id={`cat_${categoria.id_category}`}>{
                                        namesReplace[categoria.category_description]
                                    }</Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {loading ? <Loader />
                    : error ? <Message variant='danger'>{error}</Message>
                        :
                        <div className='prod_list'>
                            {products.length == 0 ?
                                <h1 className='empty_products_message'>Aún no existen productos para esta búsqueda.</h1> :
                                products.map(product => (
                                    <ProductRender data={product} />
                                ))
                            }
                        </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default ProductResult