import React from 'react'
import {Button, Card} from 'react-bootstrap'
import Rating from './Rating'
import ProgressBar from './ProgressBarf'
import {Link} from 'react-router-dom'
import { formatCurrency} from "react-native-format-currency"


function Product({ product })  {
    // To set two dates to two variables
    var date1 = Date.now();
    var date2 = Date.parse(product.vigencia);
    var diasFaltantes = Math.ceil((date2-date1)/ (1000 * 60 * 60 * 24));

    // To calculate the time difference of two dates
    //var Difference_In_Time = date2.getTime() - date1.getTime();
        
    // To calculate the no. of days between two dates
    //var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    
    /* console.log(Difference_In_Days) */

  return (
    <Card className="my-4 p-3 rounded">
        <Link to={`/product/${product._id}`}>   
            <Card.Img src={product.image} />
        </Link>
    <Card.Body>
        <Link to={`/product/${product._id}`}>
            <Card.Title as="div" >
                <strong>{ product.name }</strong>
            </Card.Title>
        </Link>
        
        <Card.Text as="h5" >
               Precio Actual:<br></br> {formatCurrency({ amount: parseInt(product.price), code: "COP" })[0]}
        </Card.Text>
        <Card.Text as="h4" >
               Precio Oferta :<br></br> {formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}  si se piden {product.compradoresMinimos} unidades entre todos.
        </Card.Text>
        
        <Card.Text as="div" >                       
                <div className='my-3'>
                    Faltan <strong>{product.compradoresFaltantes}</strong> de <strong>{product.compradoresMinimos} </strong>  unidades para que disminuya a <br></br><strong>{formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}</strong>. Ahorra: <strong>{formatCurrency({ amount: parseInt(product.price-product.nextPrice), code: "COP" })[0]}</strong>
                </div>
        </Card.Text>
        <Card.Text as="div" >                       
                <div className='my-3'>
                    <ProgressBar percentage={1-(product.compradoresFaltantes/product.compradoresMinimos)} />
                </div>
        </Card.Text>       
        <Card.Text as="div" >                       
                <div className='my-3'>                     
                    Vigencia hasta <strong>{product.vigencia}</strong>, faltan <strong>{diasFaltantes}</strong> dias.
                </div>
        </Card.Text>
        <Card.Text as="div" >                       
                <div className='my-3'>                     
                   Proveedor: <strong>{product.proveedor}</strong>
                </div>
        </Card.Text>
        <Card.Text as="div" >                       
                <div className='my-3'>                     
                    <Rating value={product.rating} text={`${product.numReviews} calificaciones`}  color={'#f8e825'} />
                </div>
        </Card.Text>
        <Link to={`/product/${product._id}`}>   
            <Button>
                Ver más
            </Button>
        </Link>

    </Card.Body>
  </Card>    
    
  )
}

export default Product
