import React, { useState, useEffect } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import { Row, Col, Image, ListGroup, Button, Card, Form, ListGroupItem } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { formatCurrency } from "react-native-format-currency"
import { getOrderDetails, payOrder } from '../actions/orderActions'
import { ORDER_PAY_RESET } from '../constants/orderConstants'
import HeaderClean from '../components/HeaderClean'
import '../styles/placeorder.css';

function OrderScreen() {
  const match = useParams();
  const navigate = useNavigate();

  const orderId = match.id
  const dispatch = useDispatch()

  const [sdkReady, setSdkReady] = useState(false)


  const orderDetails = useSelector(state => state.orderDetails)
  const { order, error, loading } = orderDetails

  const orderPay = useSelector(state => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  if (!loading && !error) {
    order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  }
  //client:AStPyR76aH3g4v5By-zFW_qEU8JJZVd72ZSiXTFsHTeF_dF-nFfxmdatDtApDLgUa3YMstwnYslwo1No
  //secret:EHtu3jzoz85Bbw-mhlcBSyJeurcMfCG4FRZhOwBX7ULYkNNhT1t3urQAIdEs_2PMjXLmfWuoKgY6bQm2

  const addPayPalScript = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.paypal.com/sdk/js?client-id=AStPyR76aH3g4v5By-zFW_qEU8JJZVd72ZSiXTFsHTeF_dF-nFfxmdatDtApDLgUa3YMstwnYslwo1No'
    script.async = true
    script.onload = () => {
      setSdkReady(true)
    }
    document.body.appendChild(script)
  }

  useEffect(() => {
    if (!order || successPay || order._id !== Number(orderId)) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch(getOrderDetails(orderId))

    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [dispatch, order, orderId, successPay])


  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult))
  }

  return (
    <>
      <HeaderClean />
      {
        loading ?
          <Loader />
          : error ?
            <Message variant='danger'> {error} </Message>
            :
            <div className='placeorder_container'>
              <h2>Datos de envió orden # {order._id}:</h2>
              <div className='placeorder_subcontainer'>
                <div className='data_info_container'>
                  <div className='info_container'>
                    <span>Ubicación</span>
                    <p><strong>Nombre: </strong> {order.user.name}</p>
                    <p><strong>Correo: </strong> <a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>
                    <p><strong>Celular: </strong> {order.shippingAddress.celular}</p>
                    <p><strong>Ciudad: </strong> {order.shippingAddress.city}</p>
                    <p><strong>Dirección: </strong> {order.shippingAddress.address}</p>
                    <p><strong>Localidad: </strong> {order.shippingAddress.localidad}</p>
                    <p><strong>Barrio: </strong> {order.shippingAddress.barrio}</p>
                    <p><strong>Celular: </strong> {order.shippingAddress.celular}</p>
                    {
                      order.isDelivered ?
                        <Message variant='success'>Entregada el {order.deliveredAt} </Message>
                        :
                        <Message variant='warning'>Aún no se entregan tus productos por parte del proveedor.  </Message>
                    }
                  </div>
                  <div className='info_container'>
                    <span>Método de pago:</span>
                    <p><strong>Método: </strong> {order.paymentMethod}</p>
                    {
                      order.isPaid ?
                        <Message variant='success'>Pagada el {order.paidAt} </Message>
                        :
                        <Message variant='warning'>Recuerda cancelar cuando sea entregado tu producto.  </Message>
                    }
                  </div>
                  <div className='info_container'>
                    <span>Productos en la orden:</span>
                    {order.orderItems.length === 0 ? <Message variant='info'>Tu orden esta vacia</Message> : (
                      <div class='place_prod_container'>
                        {order.orderItems.map((item, index) => (
                          <div key={index} className='product'>
                            <div className='product_img_container'>
                              <img src={item.image} alt={item.name} />
                              <a target='_blank' href={`/product/${item.product}`}>{item.name}</a>
                            </div>
                            <p>
                              {item.qty} X {formatCurrency({ amount: parseInt(item.price), code: "COP" })[0]}  = {formatCurrency({ amount: item.qty * parseInt(item.price), code: "COP" })[0]}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className='resumen_container'>
                  <p className='resumen_orden_titulo'>Resumen de la Orden</p>
                  <p className='resumen_order_desc border_none'>
                    Costo Total (Incluyendo Impuestos). El costo de envió depende de politicas de cada proveedor:
                    <strong> {formatCurrency({ amount: parseInt(order.itemsPrice), code: "COP" })[0]}</strong>
                  </p>
                </div>
              </div>
            </div>
      }
    </>
  )
}

export default OrderScreen
