import React, { useRef, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form, ListGroupItem } from 'react-bootstrap'
import Rating from '../components/Rating'
import Loader from '../components/Loader'
import Message from '../components/Message'
import ProgressBar from '../components/ProgressBarf'
import { listProductDetails } from '../actions/productActions'
import { formatCurrency } from "react-native-format-currency"
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/product.css'

function ProductScreen() {

    const match = useParams();
    const navigate = useNavigate();
    let [qty, setQty] = useState(0)
    const dispatch = useDispatch()
    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

    useEffect(() => {
        dispatch(listProductDetails(match.id))
    }, [dispatch, match])

    let arrayImagesProduct = (product != null) ? [product.image, product.image1] : []

    let date1 = Date.now();
    let vigencia = (product != null) ? product.vigencia : ''
    let date2 = Date.parse(vigencia);
    let diasFaltantes = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));

    const addToCartHandler = (e) => {
        let qec = document.getElementById("qec")

        if (qty == 0) {
            qec.removeAttribute("hidden")
            return
        }

        navigate(`/cart/${match.id}?qty=${qty}`)
    }

    const sumQuantityProduct = (e) => {
        let faltantes = parseInt(e.target.dataset.faltantes)
        document.getElementById("qec").setAttribute("hidden", true)

        if (qty < faltantes) {
            setQty(qty += 1)
        }
    }

    const restQuantityProduct = (e) => {
        if (qty > 0) {
            setQty(qty -= 1)
        }
    }

    return (
        <div>
            <Header />
            {/* <Button className='btn-block' type='button' onClick={() => navigate(-1)}>Regresar</Button> */}
            {loading ? <Loader />
                : error ?
                    <>
                        {/* <Message variant='danger'>Producto no encontrado</Message> */}
                        <div className='prod_portada'>
                            <h1>Producto no encontrado</h1>
                        </div>
                    </>
                    :
                    (
                        <>
                            <div className='prod_portada'>
                                <h1>{product.name}</h1>
                            </div>
                            <div className='producto_container'>
                                <div className='image_container'>
                                    {
                                        arrayImagesProduct.length == 4 ?
                                            <div className='ref_images_container'>
                                                {
                                                    arrayImagesProduct.map(productImage => (
                                                        <Button><img src={productImage} alt={product.name} /></Button>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className='ref_images_container menos_de_cuatro'>
                                                {
                                                    arrayImagesProduct.map(productImage => (
                                                        <Button><img src={productImage} alt={product.name} /></Button>
                                                    ))
                                                }
                                            </div>
                                    }

                                    <div className='current_image_container'>
                                        {/* <img className='current_image' src={product.image} alt={product.name} fluid/> */}
                                        <img className='current_image' src={product.image} alt={product.name} fluid />
                                    </div>
                                </div>
                                <div className='detail_container'>
                                    <h3> {product.name} </h3>
                                    <Rating value={product.rating} text={` ${product.numReviews} calificaciones`} color={'#f8e825'} />
                                    <strong> Descripción:</strong>
                                    <span>{product.description}</span>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col><strong>Precio Actual:</strong></Col>
                                                <Col>{formatCurrency({ amount: parseInt(product.price), code: "COP" })[0]}</Col>
                                            </Row>
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <Row>
                                                {/* <Col> Faltan <strong>{product.compradoresFaltantes}</strong> de <strong>{product.compradoresMinimos} </strong>  unidades para que disminuya a <br></br><strong>{formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}</strong>. Ahorra: <strong>{formatCurrency({ amount: parseInt(product.price - product.nextPrice), code: "COP" })[0]}</strong></Col> */}
                                                <Col> Faltan <strong>{product.compradoresFaltantes}</strong> de <strong>{product.compradoresMinimos} </strong>  unidades para que disminuya a <strong>{formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}</strong>.</Col>
                                            </Row>
                                            <Row>
                                                <Col> <ProgressBar percentage={1 - (product.compradoresFaltantes / product.compradoresMinimos)} /></Col>
                                            </Row>

                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col> Vigencia hasta <strong>{product.vigencia}</strong>, faltan <strong>{diasFaltantes}</strong> dias. </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col><strong>Proveedor:</strong></Col>
                                                <Col>{product.proveedor}</Col>
                                            </Row>
                                        </ListGroup.Item>
                                        {product.countInStock > 0 && (
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className='d-flex align-items-center'>Cantidad:</Col>
                                                    <Col xs='auto' className=''>
                                                        <div className='quantity_container'>
                                                            <div className='num_container'>
                                                                <button className='num_btn_l' onClick={restQuantityProduct}>-</button>
                                                                <span>{qty}</span>
                                                                <button className='num_btn_r' data-faltantes={product.countInStock} onClick={sumQuantityProduct}>+</button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                        }
                                        <ListGroup.Item>
                                            <Row className='footer_card'>
                                                {product.countInStock > 0 ?
                                                    <>
                                                        Apresurate quedan unidades en Inventario 😉
                                                        <Button
                                                            onClick={addToCartHandler}
                                                            className='btn-block hacer_pedido_button'
                                                            disabled={product.countInStock === 0}
                                                            type='button'>HACER PEDIDO
                                                        </Button>
                                                        <div className='quantity_error_container' id='qec' hidden>
                                                            <small>La cantidad a comprar no puede ser igual a cero.</small>
                                                        </div>
                                                    </> : 'Sin unidades disponibles 😒'
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </>
                    )
            }
            <Footer />
        </div>
    )
}

export default ProductScreen