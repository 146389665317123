import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';

function ProgressBarf({ percentage }) {
  return (
    
    <ProgressBar animated  now={percentage*100} label={`${percentage*100}%`} />
    
  )
}

export default ProgressBarf
