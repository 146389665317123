import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';

let pageForm = 1

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

const formatDate = (dateToFormat) => {
    const dateSend = new Date(dateToFormat);
    const date = `${dateSend.getFullYear()}-${formatNumber(dateSend.getMonth() + 1)}-${formatNumber(dateSend.getDate())}`
    const time = `${dateSend.getHours()}:${formatNumber(dateSend.getMinutes())}:${formatNumber(dateSend.getSeconds())}`

    return `${date}T00:00:00.000Z`
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function FacilitatorScreen() {
    const [data, setData] = useState({
        "facilitator_name": "",
        "facilitator_ape": "",
        "facilitator_cell_phone_1": "",
        "facilitator_cell_phone_2": "",
        "facilitator_email_1": "",
        "facilitator_email_2": "",
        "facilitator_city": "",
        "facilitator_neighborhood": "",
        "facilitator_address": "",
        "facilitator_birthday": "",
        "facilitator_civil_status": "",
        "facilitator_profession_occupation": "",
        "facilitator_have_children": 2,
        "facilitator_childrens": 0,
        "facilitator_commercial_task": 0,
        "facilitator_leadership": 0,
        "facilitator_comunication": 0,
        "facilitator_flexibility": 0,
        "facilitator_facebook": false,
        "facilitator_facebook_url": "",
        "facilitator_instagram": false,
        "facilitator_instagram_url": "",
        "facilitator_would_like": "",
        "captcha_text_user": ""
    })
    const inputRequireds = [
        "facilitator_name",
        "facilitator_ape",
        "facilitator_cell_phone_1",
        "facilitator_email_1",
        "facilitator_profession_occupation",
        "facilitator_city",
        "facilitator_neighborhood",
        "facilitator_address",
        "facilitator_civil_status",
        "facilitator_have_children",
        "facilitator_childrens",
        "facilitator_birthday",
        "facilitator_would_like",
        "captcha_text_user"
    ]
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [cantHijosDisabled, setCantHijosDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [captcha, setCaptcha] = useState(generateString(6))

    const refrescarCaptcha = () => {
        setCaptcha(generateString(6))
    }

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value

        if (inputName == "facilitator_have_children") {
            if (inputValue == 1) {
                setCantHijosDisabled(false)
            } else {
                setCantHijosDisabled(true)
                data['facilitator_childrens'] = 0
                if (document.getElementById(`error_facilitator_childrens`)) {
                    document.getElementById(`error_facilitator_childrens`).style.display = "none"
                    document.getElementsByName('facilitator_childrens')[0].classList.remove("error")
                }
            }
        }

        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }

    const validateCaptcha = () => {
        let validation = (captcha === data.captcha_text_user)

        if(!validation){
            document.getElementById(`error_captcha_text_user`).style.display = "block"
            document.getElementById(`error_captcha_text_user`).innerText = "El texto ingresado no coincide con el generado"
            document.getElementsByName('captcha_text_user')[0].classList.add("error")
        }
        
        return validation
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) { return }
        if (!validateCaptcha()) { return }

        let body_form = document.getElementById("body_form")
        let spinner_form = document.getElementById("spinner_form")
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        let dF = data

        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

        const dataForm = {
            "facilitator_name": (dF.facilitator_name + " " + dF.facilitator_ape),
            "facilitator_cell_phone_1": dF.facilitator_cell_phone_1,
            "facilitator_cell_phone_2": dF.facilitator_cell_phone_2,
            "facilitator_email_1": dF.facilitator_email_1,
            "facilitator_email_2": dF.facilitator_email_2,
            "facilitator_profession_occupation": dF.facilitator_profession_occupation,
            "facilitator_city": dF.facilitator_city,
            "facilitator_neighborhood": dF.facilitator_neighborhood,
            "facilitator_address": dF.facilitator_address,
            "facilitator_civil_status": dF.facilitator_civil_status,
            "facilitator_have_children": (dF.facilitator_have_children == 1) ? true : false,
            "facilitator_childrens": dF.facilitator_childrens,
            "facilitator_birthday": formatDate(dF.facilitator_birthday),
            "facilitator_commercial_task": (dF.facilitator_commercial_task == 1) ? true : false,
            "facilitator_leadership": (dF.facilitator_leadership == 1) ? true : false,
            "facilitator_comunication": (dF.facilitator_comunication == 1) ? true : false,
            "facilitator_flexibility": (dF.facilitator_flexibility == 1) ? true : false,
            "facilitator_facebook": dF.facilitator_facebook,
            "facilitator_facebook_url": (dF.facilitator_facebook) ? dF.facilitator_facebook_url : '',
            "facilitator_instagram": dF.facilitator_instagram,
            "facilitator_instagram_url": (dF.facilitator_instagram) ? dF.facilitator_instagram_url : '',
            "facilitator_would_like": dF.facilitator_would_like,
            "created_at": getCurrentDate()
        }

        axios.post('https://thor-fast-api.herokuapp.com/pre_facilitators/register', dataForm, config).then(res => {
            if (res.status == 200) {
                body_form.style.opacity = "1"
                spinner_form.style.display = "none"
                setShowModal(true)
                return true
            }
        }).catch(error => {
            console.log("Error registrando facilitador: ", error)
            body_form.style.opacity = "1"
            spinner_form.style.display = "none"
            setShowModal(true)
        });
        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

    }

    const handleChangeCheckbox = (e) => {
        let name = e.target.name
        let fb_checked = document.getElementById("fb_checked").checked
        let ig_checked = document.getElementById("ig_checked").checked

        if (name == "check_facebook" && fb_checked) {
            data.facilitator_facebook = true
            document.getElementsByName("facilitator_facebook_url")[0].removeAttribute("disabled")
        } else if (name == "check_facebook" && !fb_checked) {
            data.facilitator_facebook = false
            document.getElementsByName("facilitator_facebook_url")[0].setAttribute("disabled", true)
        } else if (name == "check_instagram" && ig_checked) {
            data.facilitator_instagram = true
            document.getElementsByName("facilitator_instagram_url")[0].removeAttribute("disabled")
        } else if (name == "check_instagram" && !ig_checked) {
            data.facilitator_instagram = false
            document.getElementsByName("facilitator_instagram_url")[0].setAttribute("disabled", true)
        } else if (name != "check_facebook" || name != "check_instagram") {
            data[name] = document.getElementById(name).checked
        }
    }

    const validateForm = () => {
        let response = true
        let currentDivStep = document.getElementById(`step_${pageForm}`)

        let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
        if (inputsCurrentStep.length > 0) {
            inputRequireds.forEach((inputName) => {
                if (data[inputName] == "" && inputsCurrentStep[inputName] && inputName != "facilitator_childrens") {
                    response = false
                    document.getElementById(`error_${inputName}`).style.display = "block"
                    document.getElementsByName(inputName)[0].classList.add("error")
                }
            })
        }

        let selectsCurrentStep = currentDivStep.getElementsByTagName("select")
        if (selectsCurrentStep.length > 0) {
            for (let item of selectsCurrentStep) {
                if (data[item.name] == "" && selectsCurrentStep[item.name]) {
                    response = false
                    document.getElementById(`error_${item.name}`).style.display = "block"
                    document.getElementsByName(item.name)[0].classList.add("error")
                }
                if (item.name == "facilitator_have_children") {
                    if (data[item.name] == 1 && data['facilitator_childrens'] == 0) {
                        document.getElementById(`error_facilitator_childrens`).style.display = "block"
                        document.getElementsByName('facilitator_childrens')[0].classList.add("error")
                    }
                }
            }
        }
        return response
    }

    const nextPageForm = (e) => {
        if (!validateForm()) { return }

        let stepFormElements = document.getElementsByClassName("stepForm");
        if (pageForm <= stepFormElements.length) {
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm += 1
            pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
            if (pageForm == stepFormElements.length) {
                e.target.setAttribute("hidden", true)
                document.getElementById("subm_btn").removeAttribute("hidden")
            } else {
                e.target.removeAttribute("hidden")
            }

            if (pageForm > 1) {
                setPrevDisabled(false)
            }
            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    const prevPageForm = (e) => {
        if (pageForm > 0) {
            let stepFormElements = document.getElementsByClassName("stepForm");
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm -= 1
            pageForm = (pageForm == 0) ? 1 : pageForm


            if (pageForm == 1) {
                setPrevDisabled(true)
            } else {
                setPrevDisabled(false)
            }

            if (pageForm < stepFormElements.length) {
                document.getElementById("next_btn").removeAttribute("hidden")
                document.getElementById("subm_btn").setAttribute("hidden", true)
            }

            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h3 className='login_title'>Conviertete en un Gestor Local</h3>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>
                    Hola <strong>{data.supplier_name}!</strong><br />
                    Nos hace muy felices que quieras ser parte de esta Colonia.<br />
                    En breve nos comunicaremos contigo.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <body className='body_facilitator'>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <div id='body_form'>
                    <div className='breadcrumbs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill active" id='breadcrumb_1' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill" id='breadcrumb_2' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                    <div className='stepForm' id='step_1' >
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nombre *">
                                    <Form.Control type="text" placeholder="Nombre *" name='facilitator_name' value={data.facilitator_name} onChange={handleChange} />
                                    <small id='error_facilitator_name' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Apellido *">
                                    <Form.Control type="text" placeholder="Apellido *" name='facilitator_ape' value={data.facilitator_ape} onChange={handleChange} />
                                    <small id='error_facilitator_ape' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Celular 1 *">
                                    <Form.Control type="number" placeholder="Celular 1 *" name='facilitator_cell_phone_1' value={data.facilitator_cell_phone_1} onChange={handleChange} />
                                    <small id='error_facilitator_cell_phone_1' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Celular 2 (opcional)">
                                    <Form.Control type="number" placeholder="Celular 2 (opcional)" name='facilitator_cell_phone_2' value={data.facilitator_cell_phone_2} onChange={handleChange} />
                            
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Correo Electrónico 1 *">
                                    <Form.Control type="email" placeholder="Correo Electrónico 1 *" name='facilitator_email_1' value={data.facilitator_email_1} onChange={handleChange} />
                                    <small id='error_facilitator_email_1' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Correo Electrónico 2 *">
                                    <Form.Control type="email" placeholder="Correo Electrónico 2 (opcional)" name='facilitator_email_2' value={data.facilitator_email_2} onChange={handleChange} />
                                    
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Municipio *">
                                    <Form.Control type="text" placeholder="Municipio *" name='facilitator_city' value={data.facilitator_city} onChange={handleChange} />
                                    <small id='error_facilitator_city' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Barrio o Vereda *">
                                    <Form.Control type="text" placeholder="Barrio o Vereda *" name='facilitator_neighborhood' value={data.facilitator_neighborhood} onChange={handleChange} />
                                    <small id='error_facilitator_neighborhood' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Dirección *">
                                    <Form.Control type="text" placeholder="Dirección *" name='facilitator_address' value={data.facilitator_address} onChange={handleChange} />
                                    <small id='error_facilitator_address' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>
                    <div className='stepForm' id='step_2' hidden>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Fecha de nacimiento *">
                                    <Form.Control type="date" placeholder="Fecha de nacimiento *" name='facilitator_birthday' value={data.facilitator_birthday} onChange={handleChange} />
                                    <small id='error_facilitator_birthday' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Estado civil *">
                                    <Form.Control type="text" placeholder="Estado civil *" name='facilitator_civil_status' value={data.facilitator_civil_status} onChange={handleChange} />
                                    <small id='error_facilitator_civil_status' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Profesión u oficio *">
                                    <Form.Control type="text" placeholder="Profesión u oficio *" name='facilitator_profession_occupation' value={data.facilitator_profession_occupation} onChange={handleChange} />
                                    <small id='error_facilitator_profession_occupation' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingSelect" label="¿Tiene hijos? *">
                                    <Form.Select aria-label="Floating label select example" name='facilitator_have_children' value={data.facilitator_have_children} onChange={handleChange} >
                                        <option>- Seleccione -</option>
                                        <option value="1">Sí</option>
                                        <option value="2">No</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <small id='error_facilitator_have_children' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Número Hijos">
                                    <Form.Control type="number" placeholder="Número Hijos" min='0' name='facilitator_childrens' value={data.facilitator_childrens} onChange={handleChange} disabled={cantHijosDisabled} />
                                    <small id='error_facilitator_childrens' className='form_error_input'>La cantidad de hijos no puede ser cero</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2'>
                            <Col md>
                                Respóndenos estas preguntas para conocerte mejor.  Te contactaremos pronto para conversar
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <label>Haz trabajado antes en labores comerciales?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="facilitator_commercial_task" id="facilitator_commercial_task" type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="facilitator_commercial_task" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <label>Te consideras una persona  con capacidad de liderazgo en tu comunidad?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="facilitator_leadership" id="facilitator_leadership" type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="facilitator_leadership" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <label>Te gusta comunicarte con las personas?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="facilitator_comunication" id="facilitator_comunication" type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="facilitator_comunication" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <label>Tienes flexibilidad de tiempo?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="facilitator_flexibility" id="facilitator_flexibility" type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="facilitator_flexibility" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <label>Tienes cuenta en Facebook?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="check_facebook" id="fb_checked" type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="check_facebook" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de Facebook *">
                                    <Form.Control type="text" placeholder="Cuenta de Facebook *" name='facilitator_facebook_url' value={data.facilitator_facebook_url} onChange={handleChange} disabled />
                                    <small id='error_facilitator_facebook_url' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <label>Tienes cuenta en Instagram?</label>
                                <div>
                                    <Form.Check inline label="Sí" name="check_instagram" id='ig_checked' type='radio' onChange={handleChangeCheckbox} />
                                    <Form.Check inline label="No" name="check_instagram" type='radio' onChange={handleChangeCheckbox} />
                                </div>
                                <small id='error_facilitator_facebook' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de Instagram *">
                                    <Form.Control type="text" placeholder="Cuenta de Instagram *" name='facilitator_instagram_url' value={data.facilitator_instagram_url} onChange={handleChange} disabled />
                                    <small id='error_facilitator_instagram_url' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2'>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuéntanos  por qué te gustaria ser parte de Tu Colonia? *">
                                    <Form.Control type="textarea" maxLength='55' placeholder="Cuéntanos  por qué te gustaria ser parte de Tu Colonia? *" name='facilitator_would_like' value={data.facilitator_would_like} onChange={handleChange} />
                                    <small id='error_facilitator_would_like' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2 captcha'>
                            <Col md>
                                <label>Captcha</label>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInputGrid">
                                        <Form.Control type="text" className="input_disabled_captcha" value={captcha} disabled/>
                                        <small id='error_facilitator_would_like' className='form_error_input'>Por favor rellene este campo</small>
                                    </FloatingLabel>
                                    <Button title='Refrescar captcha' onClick={refrescarCaptcha}>
                                        <img src={refresh} />
                                    </Button>
                                </InputGroup>
                            </Col>
                            <Col md>
                                <label>Ingresa el texto del captcha *</label>
                                <FloatingLabel controlId="floatingInputGrid" label="Ingresa el texto del captcha *">
                                    <Form.Control type="text" placeholder="Ingresa el texto del captcha *" name='captcha_text_user' value={data.captcha_text_user} onChange={handleChange} />
                                    <small id='error_captcha_text_user' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>

                    <Row className='action_btn_container'>
                        <Button className='prev_btn' id='prev_btn' onClick={prevPageForm} disabled={prevDisabled}>Atrás</Button>
                        <Button className='next_btn' id='next_btn' onClick={nextPageForm} >Siguiente</Button>
                        <Button className='next_btn' id='subm_btn' type="submit" hidden>Enviar</Button>
                    </Row>
                </div>
            </Form>
            </body>
            <Footer />
        </>
    )
}

export default FacilitatorScreen
