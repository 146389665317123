import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import HeaderClean from '../components/HeaderClean'
import Footer from '../components/Footer'
import '../styles/login.css';

import { login, loginAll } from '../actions/userActions'

function LoginScreen() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const dispatchAll = useDispatch()
  const match = useParams()
  const Location = useLocation()
  const navigate = useNavigate()
  const redirect = Location.search ? Location.search.split('=')[1] : '/'
  const userLogin = useSelector(state => state.userLogin)
  const { error, loading, userInfo } = userLogin
  const userAllLogin = useSelector(state => state.userAllLogin)
  const { errorA, loadingA, userInfoA } = userAllLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(loginAll(email))
      navigate(redirect)
    }
  }, [navigate, userInfo, userInfoA, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <div>
      <HeaderClean />
      <FormContainer>
        {error && <Message variant='danger'> {"No se encontró una cuenta activa para estas credenciales 😒"} </Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler} className='login_form'>
          <Row>
            <h1 className='login_title'>Iniciar sesión</h1>
          </Row>
          <Form.Group controlId='email'>
            <Form.Label>E-mail </Form.Label>
            <Form.Control type='email' placeholder='Ingresa tu correo eléctronico' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group controlId='password'>
            <Form.Label>Contraseña </Form.Label>
            <Form.Control type='password' placeholder='Ingresa tu contraseña' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
          </Form.Group>
          <Button className='my-3 login_button' type='submit' variant='primary'>Ingresa a TuColonia.co</Button>
          <Link className='register_redirect_button' to={redirect ? `/register?redirect=${redirect}` : '/register'}>Crear una cuenta</Link>
          <div className='password_reset_container'>
            <span className='login_span'>¿Olvidaste tu contraseña 😨?</span>
            {/* <a className='login_link' href="https://backend.tucolonia.co/api/users/accounts/password_reset/">Recuperala aquí</a> */}
            <a className='login_link' href="/password_reset_email/">Recupera tu contraseña</a>
          </div>
        </Form>
      </FormContainer>
      <Footer />
    </div>
  )
}

export default LoginScreen
