import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom';
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listCategories } from '../actions/categoryActions'
import { listCategoryProductDetails, listAllProductsDetails, getPromotions } from '../actions/productActions'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/allproducts.css';
import ProductRender from './ProductRenderScreen';


function resaltarCategoria(id) {
    id = (typeof id === "undefined") ? 0 : id
    let cat_tag = document.getElementsByClassName('link_cat');
    Array.from(cat_tag).forEach(function (element) {
        let idElement = element.getAttribute('id');
        if (idElement.includes('' + id)) {
            element.style.cssText = "text-decoration: underline";
        } else {
            element.style.cssText = "text-decoration: none";
        }
    });
}

function CategoryScreen() {
    const match = useParams()
    const namesReplace = {
        "Hogar (Alimentos, Víveres, Aseo)": "Hogar",
        "Autos y Motos (Servicios y Repuestos)": "Autos y Motos",
        "Bicicletas (Servicios y Accesorios)": "Bicicletas",
        "Productos y Servicios para tu mascota": "Productos y Servicios para tu mascota",
        "Otros (Vestuario, libros, seguros, viajes, reparaciones)": "Otros",
        "Equipos y enseres (electrodomésticos, muebles, accesorios)": "Equipos y Enseres"
    }

    //Se obtienen las categorias
    const dispatchCategories = useDispatch()
    const categoryList = useSelector(state => state.categoryList)
    const { categories } = categoryList
    categories.sort((a, b) => (a.category_name > b.category_name) ? 1 : -1)
    
    

    //Obtenemos las promociones
    const dispatchPromotions = useDispatch()
    const promotionList = useSelector(state => state.promotionsList)
    const { loadingProm, errorProm, promotions } = promotionList

    //Se obtienen los productos
    const dispatchCategoryProd = useDispatch()
    const productCategoryList = useSelector(state => state.productCategoryList)
    const { loading, error, products } = productCategoryList

    useEffect(() => {
        dispatchCategories(listCategories())
        dispatchPromotions(getPromotions(match.id))
        dispatchCategoryProd((match.id) ? listCategoryProductDetails(match.id) : listAllProductsDetails())
    }, [dispatchCategories, dispatchPromotions, dispatchCategoryProd, match])

    resaltarCategoria(match.id)

    return (
        <div>
            <Header />
            <div className='prod_portada'>
                <h3>Nuestros productos</h3>
            </div>
            <div className='prod_container'>
                <div className='filters'>
                    <div className='filter_cat filter_box'>
                        <h4 className='filter_title'>Categorias</h4>
                        <div className='filter_container'>
                            <Link to='/products/' className='link_cat' id='cat_0'>Todos</Link>                            {
                                categories.map(categoria => (                                    
                                    <Link to={`/products/${categoria.id_category}`} className='link_cat' id={`cat_${categoria.id_category}`}>{
                                        namesReplace[categoria.category_description]
                                    }</Link>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='prod_list'>
                    {/* CARGAMOS LAS PROMOCIONES */}
                    {loadingProm ? <Loader />
                        : errorProm ? <Message variant='danger'>{errorProm}</Message>
                            :
                            <>
                                {/* {
                                    promotions.length == 0 ? null :
                                        promotions.map(promotion => (
                                            <ProductRender data={promotion} />
                                        ))
                                } */}
                                {/* CARGAMOS LOS PRODUCTOS */}
                                {loading ? <Loader />
                                    : error ? <Message variant='danger'>{error}</Message>
                                        :
                                        products.length == 0 ?
                                            <h1 className='empty_products_message'>Aún no existen productos para esta categoria.</h1> :
                                            products.map(product => (
                                                <ProductRender data={product} />
                                            ))
                                }
                            </>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CategoryScreen
