import React from "react";
import '../styles/navMobile.css'
import { Link } from "react-router-dom";

function NavMobile({toggleNavMobile}){

    return(
        <div className="nav_mobile_container" id="nav_mobile_container">
            <div className="header_nav_mobile">
                <svg onClick={toggleNavMobile} xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                </svg>
            </div>
            <div className="list_nav_mobile">
                <a href="#como_funciona">Cómo Funciona</a>
                <Link to="/products">Promos</Link>
                <Link to="/facilitator">Trabaja Como Gestor Local</Link>
                <Link to="/supplier">Trabaja Como Proveedor</Link>
            </div>
        </div>
    )

}

export default NavMobile