import React, { useEffect } from "react";
import CustomCarousel from "./CustomCarousel";
import { useDispatch, useSelector } from "react-redux";
import { getPromotions } from "../actions/productActions";
import Loader from "./Loader";

import '../styles/promotions.css';

function Promotions() {
    const dispatchPromotions = useDispatch()
    const promotionList = useSelector(state => state.promotionsList)
    const { loading, error, promotions } = promotionList

    useEffect(() => {
        dispatchPromotions(getPromotions())
    }, [dispatchPromotions])

    return (
        <div className="promotions">
            <h1>NO TE PIERDAS NINGUNA PROMOCIÓN</h1>
            <p>Busca la Promo de Compras Colectivas Inteligentes que mas de convenga.  Si no encuentras el producto o servicio que desees,  puedes escribirnos  y empezamos a trabajar para  que pronto  tengas ahorros colectivos.</p>
            {loading ? <Loader />
            : error ? <div class='error_message'>{error}</div>
                :
                <>
                {
                    promotions.length == 0 ?
                    null :
                    <CustomCarousel data={promotions} type='promotion'/>
                }
                </>
            }
            <a href="/products/" className='promotions_button2'>VER TODAS LAS PROMOS</a>
        </div>
    );
}

export default Promotions;