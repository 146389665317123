import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function FormContainer({children} ) {
  return (
    <Container className='container_login_register'>
        <Row className="justify-content-md-center">
            <Col xs={12} md={8}>
                 {children}
            </Col>            
        </Row>      
    </Container>
  )
}

export default FormContainer
