import React from "react";
import { formatCurrency } from "react-native-format-currency";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from '../components/ProgressBarf';
import Rating from '../components/Rating'
import { useEffect } from "react";

function ProductRender(props) {
    let product = props.data
    const navigate = useNavigate()

    const addToCartHandler = (e) => {
        let idProduct = parseInt(e.target.dataset.id)
        let quantity = parseInt(document.getElementById("quantity_" + idProduct).innerText)
        let qec = document.getElementById("qec_" + idProduct)

        if (quantity == 0) {
            qec.removeAttribute("hidden")
            return
        }

        navigate(`/cart/${idProduct}?qty=${quantity}`)
    }

    const sumQuantityProduct = (e) => {
        let faltantes = parseInt(e.target.dataset.faltantes)
        let idProduct = parseInt(e.target.dataset.id)
        let quantityElement = document.getElementById("quantity_" + idProduct)
        let quantity = parseInt(quantityElement.innerText)
        document.getElementById("qec_" + idProduct).setAttribute("hidden", true)

        // if (quantity < faltantes) {
        //     quantityElement.innerText = (quantity += 1)
        // }
        quantityElement.innerText = (quantity += 1)
    }

    const restQuantityProduct = (e) => {
        let idProduct = parseInt(e.target.dataset.id)
        let quantityElement = document.getElementById("quantity_" + idProduct)
        let quantity = parseInt(quantityElement.innerText)

        if (quantity > 0) {
            quantityElement.innerText = (quantity -= 1)
        }
    }

    //cargamos las cantidades que se tengan agregadas en el carrito
    useEffect(() => {
        let cartItems = JSON.parse(localStorage.getItem("cartItems"))
        if (cartItems != null) {
            cartItems.forEach(element => {
                let quantityElement = document.getElementById("quantity_" + element.product)
                if(quantityElement){
                    quantityElement.innerText = element.qty
                }
            });
        }
    })

    return (
        <div className='prod_item'>
            <Link className='prod_img_box' to={`/product/${product._id}`}>
                <img className='prod_img' src={product.image} />
                <img className='prod_img_hover' src={product.image1} />
            </Link>
            <div className='prod_text_box'>
                <Link to={`/product/${product._id}`}>
                    <p className='prod_title'>{product.name}</p>
                    <div className='prices'>
                        <p className='offer_price' title='Precio de oferta'>
                            {formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}
                        </p>
                        <p className='normal_price' title='Precio normal'>
                            {formatCurrency({ amount: parseInt(product.price), code: "COP" })[0]}
                        </p>
                    </div>
                    <p className='prod_desc'>
                        Faltan <strong>{product.compradoresFaltantes}</strong> de <strong>{product.compradoresMinimos}</strong> unidades para que disminuya a
                        <strong> {formatCurrency({ amount: parseInt(product.nextPrice), code: "COP" })[0]}</strong>
                    </p>
                    <div className='progres_bar_container'>
                        <ProgressBar percentage={1 - (product.compradoresFaltantes / product.compradoresMinimos)} />
                    </div>
                    <p className='prod_prov'>
                        Proveedor: <strong>{product.proveedor}</strong>
                    </p>
                    <div className='rating_container'>
                        <Rating value={product.rating} text={`${product.numReviews} calificaciones`} color={'#f8e825'} />
                    </div>
                </Link>
                {
                    (product.countInStock > 0) ?
                        <>
                            <div className='quantity_container'>
                                <div className='num_container'>
                                    <button className='num_btn_l' data-id={product._id} onClick={restQuantityProduct}>-</button>
                                    <span id={`quantity_${product._id}`}>0</span>
                                    <button className='num_btn_r' data-id={product._id} data-faltantes={product.compradoresFaltantes} onClick={sumQuantityProduct}>+</button>
                                </div>
                                <button className='button_set_cart' data-id={product._id} onClick={addToCartHandler}>Agregar al carrito</button>
                            </div>
                            <div className='quantity_error_container' id={`qec_${product._id}`} hidden>
                                <small>La cantidad a comprar no puede ser igual a cero.</small>
                            </div>
                        </>
                        :
                        <div className='quantity_container'>
                            Sin unidades disponibles 😒
                        </div>
                }
            </div>
        </div>
    )
}

export default ProductRender