import React, { useState } from 'react';
import axios from 'axios';

function GasolinaScreenv2() {
    const [formData, setFormData] = useState({
        license_plate_car: '',
        brand_car: '',
        name_owner: '',
        cellphone_owner: '',
        email_owner: '',
        created_at: new Date().toISOString(), // Fecha generada automáticamente
      });
      const [errorMessage, setErrorMessage] = useState('');
      const [successMessage, setSuccessMessage] = useState('');
      const [formSubmitted, setFormSubmitted] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post('https://thor-fast-api.herokuapp.com/cars/register', formData);
          console.log('Solicitud POST exitosa:', response.data);
          setSuccessMessage('Registro exitoso.'); // Mostrar mensaje de éxito
          setErrorMessage(''); // Limpiar cualquier mensaje de error anterior
        } catch (error) {          
            console.error('Error al enviar la solicitud POST:', error);

            // Verificar si el error es de tipo AxiosError y si hay una respuesta definida
            if (axios.isAxiosError(error)) {
              const errorDetails = JSON.stringify({
                message: error.message,
                name: error.name,
                config: error.config,
                code: error.code,
                status: error.response ? error.response.status : null,
              }, null, 2); // Convierte los detalles del error en una cadena JSON formateada
              setErrorMessage(errorDetails);
            } else {
              // Si no es un error de Axios, muestra el mensaje de error por defecto
              setErrorMessage(error.toString());
            }
          
            setSuccessMessage(''); // Limpiar cualquier mensaje de éxito anterior
        } finally {
          setFormSubmitted(true); // Marcar que el formulario ha sido enviado
        }
      };
    
      return (
        <div>
          <h2>Formulario de Registro de Coche</h2>
          {formSubmitted ? (
            // Mostrar mensaje de éxito o error después de enviar el formulario
            <>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            </>
          ) : (
            // Mostrar el formulario mientras no se haya enviado
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="license_plate_car">Matrícula del Coche:</label>
                <input
                  type="text"
                  id="license_plate_car"
                  name="license_plate_car"
                  value={formData.license_plate_car}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="brand_car">Marca del Coche:</label>
                <input
                  type="text"
                  id="brand_car"
                  name="brand_car"
                  value={formData.brand_car}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="name_owner">Nombre del Propietario:</label>
                <input
                  type="text"
                  id="name_owner"
                  name="name_owner"
                  value={formData.name_owner}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="cellphone_owner">Teléfono del Propietario:</label>
                <input
                  type="text"
                  id="cellphone_owner"
                  name="cellphone_owner"
                  value={formData.cellphone_owner}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email_owner">Email del Propietario:</label>
                <input
                  type="email"
                  id="email_owner"
                  name="email_owner"
                  value={formData.email_owner}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Enviar</button>
            </form>
          )}
        </div>
      );
    }
export default GasolinaScreenv2;
