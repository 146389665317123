import React, { useState } from "react";
import '../styles/card_sections.css';

import aliadosAvatar from '../images/characters/character-11.png';
import { shoppingCart } from "./Images";
import rayo from '../images/rayo.png';
import union from '../images/union.png';
import nosotros from '../images/nosotros.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../styles/compartir.css'

function CardSections(){
    const [mostrarPanel, setMostrarPanel] = useState(false);

    const togglePanel = () => {
        setMostrarPanel((prevState) => !prevState);
      };

    const textoCompartir = '¡Estas a un paso de compartir el ahorro!';
    const urlCompartir = 'https://tucolonia.co'; // Reemplaza con la URL que deseas compartir

    const compartirEnFacebook = () => {
        const urlFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlCompartir)}`;
        window.open(urlFacebook, '_blank');
        setMostrarPanel(false); // Oculta el panel después de compartir
    };

    const compartirEnWhatsApp = () => {
        const urlWhatsApp = `https://api.whatsapp.com/send?text=${encodeURIComponent(textoCompartir + ' ' + urlCompartir)}`;
        window.open(urlWhatsApp, '_blank');
        setMostrarPanel(false); // Oculta el panel después de compartir
    };

    const compartirEnInstagram = () => {
        // Instagram no proporciona una forma oficial de compartir a través de la web
        // Pero puedes abrir Instagram con el texto y enlace en el portapapeles para que el usuario lo pegue manualmente
        const textoCopiar = textoCompartir + ' ' + urlCompartir;
        navigator.clipboard.writeText(textoCopiar);
        alert('Texto copiado al portapapeles para compartir en Instagram.');
        setMostrarPanel(false); // Oculta el panel después de compartir
    };
    return(
        <div className="cards_section_container" id="conocenos">
            <h1>Un ecosistema para la colectividad</h1>
            <p>
                En Tu Colonia, la unión hace el ahorro, la unión genera bienestar.
                <br></br>
                <br></br>
                Tú  compras de forma inteligente, Tu vendes de forma inteligente.  Únete a Tu Colonia  como Cliente, Proveedor o Gestor Local de  Colonias en tu comunidad.
                <br></br>
                <br></br>
                <strong>Eres bienvenido!!!</strong>
            </p>
            <div className="cards_container">
                <div className="card card_aliados">
                    <div className="card_img_container">
                        <img src={aliadosAvatar} />      
                    </div>
                    <div className="card_text_container">
                        <h1>10,000+ Aliados proveedores</h1>
                        <p>Cada día son más los proveedores que se unen a la red de compras colectivas inteligentes que esta cambiando el mundo. Únete  y aprovecha  un nuevo canal de ventas, mas eficiente, sin riesgos y mas justo para todos.</p>
                        <a href="/supplier">Quiero ser Aliado PROVEEDOR ➝</a>
                    </div>
                </div>
                <div className="card card_promo">
                    <div className="card_img_container">
                        <img src={shoppingCart} />      
                    </div>
                    <div className="card_text_container">
                        <h1>¿No encontraste la promo que buscabas?</h1>
                        <p><strong>Te escuchamos!!!</strong><br></br>Dinos los productos y servicios que te gustaría encontrar en Tu Colonia. Si mas personas los piden, obtendrás grandes ahorros. Escribenos contandonos sobre el producto que buscas. Nosotros hacemos el resto.</p>
                        <a href="/make_wish">Pide un Deseo +</a>
                    </div>
                </div>
            </div>
            <div className="card card_union">
                <div className="first_div">
                    <div className="card_img_container">
                        <img src={rayo} />      
                    </div>
                    <div className="card_text_container">
                        <h1>La unión hace la fuerza</h1>
                        <p>
                            Lo bueno se comparte, y mejor si eso te da descuentos. Si tienes vecinos, amigos o colegas muéstrales el poder de las compras colectivas inteligentes y obtén mayores descuentos.
                        </p>
                        <div className="compartir-container">
                            <button onClick={togglePanel}>Compartir</button>
                            {mostrarPanel && (
                                <div className="panel-emergente">
                                    <div className="opcion-compartir" onClick={compartirEnFacebook}>
                                      <FontAwesomeIcon icon={faFacebook} /> Compartir en Facebook
                                    </div>
                                    <div className="opcion-compartir" onClick={compartirEnWhatsApp}>
                                        <FontAwesomeIcon icon={faWhatsapp} /> Compartir en WhatsApp
                                    </div>
                                    <div className="opcion-compartir" onClick={compartirEnInstagram}>
                                        <FontAwesomeIcon icon={faInstagram} /> Compartir en Instagram
                                    </div>
                                </div>
                            )}
                            </div>
                    </div>
                </div>
                <img className="card_img" src={union} />
            </div>
            <div className="card card_work">
                <div className="card_text_container">
                    <h1>Trabaja con nosotros</h1>
                    <p>Sé un <strong>Gestor Local de Tu Colonia</strong> Gratis, Rápido y Fácil. Obtienes ingresos promoviendo la Compras Colectivas Inteligentes.</p>
                    <p>Ventajas:</p>
                    <ul>
                        <li>No tienes que invertir, no tienes que comprar productos o servicios, no tienes que hacer cobros.</li>
                        <li>Solo impulsas en tu comunidad las inscripciones a Tu Colonia y motivas para que se hagan pedidos en el sistema.</li>
                        <li>Tu ganas por las compras efectivas que ellos hagan en la plataforma.</li>
                        <li>El sistema lleva las cuentas de tus ganancias automáticamente  y  pagamos a tu cuenta Nequi o Daviplata.</li>
                    </ul>
                    <a href="/facilitator">Regístrate</a>
                </div>
                <img className="card_img" src={nosotros} />
            </div>
            <div className="card_create_account">
                <h1>Crea tu cuenta ahora</h1>
                <p>Sin costo<br></br>Rápido<br></br>Fácil</p>
                <a href="/register">Registrarme</a>
            </div>
        </div>
    );
}

export default CardSections;
