import React, { useState, useEffect } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import { getUserDetails } from '../actions/userActions'
import Message from '../components/Message'
import { addToCart, removeFromCart, saveShippingAddress, savePaymentMethod } from '../actions/cartActions'
import { formatCurrency } from "react-native-format-currency"
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import HeaderClean from '../components/HeaderClean';
import Footer from '../components/Footer';
import '../styles/shipping.css';



function PaymentScreen() {
  const cart = useSelector(state => state.cart)
  const { shippingAddress } = cart

  const match = useParams()
  const Location = useLocation()
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const [paymentMethod, setPaymentMethod] = useState('Efectivo')
  if (!shippingAddress.direccion) {
    navigate(`/shipping`)

  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    navigate(`/placeorder`)

  }
  return (
    <>
      <HeaderClean />
      <div className='shipping_container'>
        <FormContainer>
          <h2>Seleccionar método de pago</h2>
          <CheckoutSteps step1 step2 step3 />
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Col>
                <Form.Check type='radio'
                  label='Efectivo. Contra-entrega'
                  id='Efectivo'
                  name='paymentMethod'
                  checked
                  onChange={(e) => setPaymentMethod("Efectivo")}>
                </Form.Check>
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Continuar
            </Button>
          </Form>
        </FormContainer>
      </div>
      <Footer />
    </>
  )
}

export default PaymentScreen
