import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from "../components/Footer";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import '../styles/facilitator.css';

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

function MakeWishScreen() {
    const [data, setData] = useState({ "product": "", "full_name": "", "phone": "", "email": "", "city": "" })
    const inputRequireds = ["product", "full_name", "phone", "email", "city"]
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value

        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validateForm = () => {
        let response = true
        let inputsForm = document.getElementsByTagName("input")
        if (inputsForm.length > 0) {
            inputRequireds.forEach((inputName) => {
                let element = document.getElementsByName(inputName)[0]
                let errorSpan = document.getElementById(`error_${inputName}`)

                if (data[inputName] == "") {
                    response = false
                    errorSpan.innerText = "Por favor rellene este campo"
                    errorSpan.style.display = "block"
                    element.classList.add("error")
                }

                if (element.getAttribute('type') == "email" && !isValidEmail(data[inputName]) && data[inputName] != "") {
                    response = false
                    errorSpan.innerText = "Por favor ingrese un correo valido"
                    errorSpan.style.display = "block"
                    element.classList.add("error")
                }
            })
        }
        return response
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) { return }
        console.table(data)
        setShowModal(true)
    }

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h1>Pide un Deseo</h1>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>Gracias por pedir tu deseo, trabajaremos en ello para hacerlo realidad.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <Row className='g-2'>
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Producto o servicio que deseas (max 50 caracteres) *">
                            <Form.Control type="textarea" maxLength='50' placeholder="Producto o servicio que deseas (max 50 caracteres) *" name='product' value={data.product} onChange={handleChange} />
                            <small id='error_product' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Nombre Completo *">
                            <Form.Control type="text" placeholder="Nombre Completo *" name='full_name' value={data.full_name} onChange={handleChange} />
                            <small id='error_full_name' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Número Celular *">
                            <Form.Control type="number" placeholder="Número Celular *" name='phone' value={data.phone} onChange={handleChange} />
                            <small id='error_phone' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Correo Electronico *">
                            <Form.Control type="email" placeholder="Correo Electronico *" name='email' value={data.email} onChange={handleChange} />
                            <small id='error_email' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Ciudad *">
                            <Form.Control type="text" placeholder="Ciudad *" name='city' value={data.city} onChange={handleChange} />
                            <small id='error_city' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className='action_btn_container'>
                    <Button className='next_btn' id='subm_btn' type="submit">Enviar</Button>
                </Row>
            </Form>
            <Footer />
        </>
    )
}

export default MakeWishScreen