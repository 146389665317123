import axios from 'axios'
import { 
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_LIST_CATEGORY_REQUEST,
    PRODUCT_LIST_CATEGORY_SUCCESS,
    PRODUCT_LIST_CATEGORY_FAIL,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL, 

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,

} from '../constants/productConstants'


export const listProducts = () => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_LIST_REQUEST})
     
        const {data}  = await axios.get('https://thor-fast-api.herokuapp.com/products/front/')

        dispatch({type:PRODUCT_LIST_SUCCESS,
                  payload: data
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const listProductDetails = (id) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_DETAILS_REQUEST})

        const {data}  = await axios.get(`https://thor-fast-api.herokuapp.com/products/front/${id}`)

        dispatch({type:PRODUCT_DETAILS_SUCCESS,
                  payload: data
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const listAllProductsDetails = () => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_LIST_CATEGORY_REQUEST})

        const {data}  = await axios.get(`https://thor-fast-api.herokuapp.com/products/front/`)

        dispatch({type:PRODUCT_LIST_CATEGORY_SUCCESS,
                  payload: data
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_LIST_CATEGORY_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const listProductsByName = (name) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_LIST_CATEGORY_REQUEST})

        const {data}  = await axios.get(`https://thor-fast-api.herokuapp.com/products/front_search_name/${name}`)
        const prodsD = await getDiscountProduct(data)

        dispatch({type:PRODUCT_LIST_CATEGORY_SUCCESS,
                  payload: prodsD
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_LIST_CATEGORY_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const listCategoryProductDetails = (id) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_LIST_CATEGORY_REQUEST})

        const {data}  = await axios.get(`https://thor-fast-api.herokuapp.com/products/frontprodcategory/${id}`)

        dispatch({type:PRODUCT_LIST_CATEGORY_SUCCESS,
                  payload: data
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_LIST_CATEGORY_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `https://backend.tucolonia.co/api/products/delete/${id}/`,
            config
        )

        dispatch({
            type: PRODUCT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProduct = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `https://backend.tucolonia.co/api/products/create/`,
            {},
            config
        )
        dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `https://backend.tucolonia.co/api/products/update/${product._id}/`,
            product,
            config
        )
        dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProductReview = (productId, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `https://backend.tucolonia.co/api/products/${productId}/reviews/`,
            review,
            config
        )
        dispatch({
            type: PRODUCT_CREATE_REVIEW_SUCCESS,
            payload: data,
        })



    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

//Acciones nuevo backend

async function getDiscountProduct(products){
    let responseProducts = products
    for(const key in products){
        await fetch(`https://thor-fast-api.herokuapp.com/discounts/product/${products[key].id_product}`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            let dataD = data[data.length - 1]
            if(dataD != undefined){
                responseProducts[key].id_discount = dataD.id_discount
                responseProducts[key].minimum_quantity = dataD.minimum_quantity
                responseProducts[key].maximum_quantity = dataD.maximum_quantity
                responseProducts[key].discount_price = dataD.discount_price
            }
        })
    }
    return responseProducts
}

export const getPromotions = (id_category = null) => async (dispatch) => {
    try{
        //real: https://thor-fast-api.herokuapp.com/promotions/
        //fake: https://api.npoint.io/a1003d5e361f59348dc4
        dispatch({type:PRODUCT_LIST_REQUEST})

        const {data}  = await axios.get(`https://thor-fast-api.herokuapp.com/promotions/`)
        let prodsP = await getProductDetail(data)
        if(id_category != null){
            prodsP = prodsP.filter(promotion => promotion.id_category == id_category);
        }

        dispatch({type:PRODUCT_LIST_SUCCESS,
                  payload: prodsP
                 })
    }catch(error){
        dispatch({
            type:PRODUCT_LIST_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

async function getProductDetail(promotions){
    let promotionsProducts = promotions
    //real: https://thor-fast-api.herokuapp.com/products/1
    for(const key in promotions){
        await fetch(`https://thor-fast-api.herokuapp.com/products/${promotions[key].id_product}`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            if(data.product_name){
                promotionsProducts[key].product_name = data.product_name
                promotionsProducts[key].product_image_1 = data.product_image_1
                promotionsProducts[key].product_image_2 = data.product_image_2
                promotionsProducts[key].product_description = data.product_description
                promotionsProducts[key].product_price = data.product_price
                promotionsProducts[key].product_delivery_price = data.product_delivery_price
                promotionsProducts[key].utility_product = data.utility_product
                promotionsProducts[key].id_category = data.id_category
            }
        })
        .catch(error =>{
            console.log("error: ", error)
        })
    }
    return promotionsProducts
}