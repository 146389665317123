import React, { useRef, useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import '../styles/sliderHome.css';
import '../styles/navMobile.css'; // Estilos para dispositivos móviles

import img1 from '../images/slider/comprascolectivasinteligentes.mp4';
import img2 from '../images/slider/PromoGasolina.mp4';
import img3 from '../images/slider/PromoMascotas.mp4';

function SliderHome() {
    const carouselRef = useRef(null);
    const [videosPlaying, setVideosPlaying] = useState(false);

    const videos = [
      { url: img1, link: '/register' },
      { url: img2, link: '/gasolina' },
      { url: img3, link: '/products/4' },
    ];
  
    const handleVideoEnded = () => {
      // Cambia al siguiente video cuando un video haya terminado
      carouselRef.current.next();
    };
  
    useEffect(() => {
      // Reproducir los videos una vez que la página esté lista
      setVideosPlaying(true);
    }, []);
  
    return (
      <div className='slider_home'>
        <Carousel ref={carouselRef} controls={false}>
          {videos.map((video, index) => (
            <Carousel.Item key={index}>
               <div className="video-container" style={{ height: window.innerWidth > 767 ? `calc(50vw * 0.5625)` : `calc(90vw * 0.5625)` }}> 
               
                <Link to={video.link}>
                  <ReactPlayer
                    url={video.url}
                    playing={videosPlaying} // Controlar manualmente la reproducción
                    loop
                    muted
                    controls={false}
                    onEnded={handleVideoEnded}
                    width="100%"
                    height="100%"
                    // objectFit="cover"
                  />
                </Link>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
  
  export default SliderHome;
