import React, { useState, useEffect } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form, ListGroupItem } from 'react-bootstrap'
import { getUserDetails } from '../actions/userActions'
import Message from '../components/Message'
import { addToCart, removeFromCart, saveShippingAddress } from '../actions/cartActions'
import { formatCurrency } from "react-native-format-currency"
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import HeaderClean from '../components/HeaderClean';
import Footer from '../components/Footer';
import '../styles/placeorder.css';

function PlaceOrderScreen() {

  const orderCreate = useSelector(state => state.orderCreate)
  const { order, error, success } = orderCreate
  const dispatch = useDispatch()

  const cart = useSelector(state => state.cart)
  cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  cart.shippingPrice = cart.itemsPrice > 100000 ? 0 : 0
  cart.taxPrice = cart.itemsPrice - (cart.itemsPrice / 1.19)
  cart.totalPrice = cart.itemsPrice
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`)
      dispatch({ type: ORDER_CREATE_RESET })
    }
  }, [navigate, success])
  const placeOrder = () => {
    dispatch(createOrder({
      orderItems: cart.cartItems,
      shippingAddress: cart.shippingAddress,
      paymentMethod: cart.paymentMethod,
      itemsPrice: cart.itemsPrice,
      shippingPrice: cart.shippingPrice,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice,

    }))
  }
  return (
    <>
      <HeaderClean />
      <div className='placeorder_container'>
        <h2>validar datos de envio</h2>
        <CheckoutSteps step1 step2 step3 step4 />
        <div className='placeorder_subcontainer'>
          <div className='data_info_container'>
            <div className='info_container'>
              <span>Ubicación</span>
              <p><strong>Ciudad: </strong>{cart.shippingAddress.ciudad}</p>
              <p><strong>Dirección: </strong>{cart.shippingAddress.direccion}</p>
              <p><strong>Localidad: </strong>{cart.shippingAddress.localidad}</p>
              <p><strong>Barrio: </strong>{cart.shippingAddress.barrio}</p>
              <p><strong>Celular: </strong>{cart.shippingAddress.celular}</p>
            </div>
            <div className='info_container'>
              <span>Método de pago</span>
              <p><strong>Método: </strong>{cart.paymentMethod}</p>
            </div>
            <div className='info_container'>
              <span>Productos en la orden</span>
              {cart.cartItems.length === 0 ? <Message variant='info'>Tu carro de compras esta vacio</Message>
                : (
                  <div class='place_prod_container'>
                    {cart.cartItems.map((item, index) => (
                      <div key={index} className='product'>
                        <div className='product_img_container'>
                          <img src={item.image} alt={item.name} />
                          <a target='_blank' href={`/product/${item.product}`}>{item.name}</a>
                        </div>
                        <p>
                          {item.qty} X {formatCurrency({ amount: parseInt(item.price), code: "COP" })[0]}  = {formatCurrency({ amount: item.qty * parseInt(item.price), code: "COP" })[0]}
                        </p>
                      </div>
                    ))}
                  </div>

                )}
            </div>
          </div>
          <div className='resumen_container'>
            <p className='resumen_orden_titulo'>Resumen de la Orden</p>
            <p className='resumen_order_desc'>
              Costo Total (Incluyendo Impuestos). El costo de envió depende de politicas de cada proveedor:
              <strong> {formatCurrency({ amount: parseInt(cart.itemsPrice), code: "COP" })[0]}</strong>
            </p>
            <div className='resumen_button_container'>
              {error && <Message></Message>}
              <Button type='button' className='btn-block' disabled={cart.cartItems === 0} onClick={placeOrder}>Confirmar Pedido</Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PlaceOrderScreen
