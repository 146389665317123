import React, { useState } from "react";
import HeaderClean from "../components/HeaderClean";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import '../styles/passwordReset.css';

function PasswordReset(){
    //https://backend.tucolonia.co/api/users/accounts/password_reset/
    const [data, setData] = useState({
        "first_pass": "",
        "second_pass": ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        if(data.first_pass == ""){
            document.getElementById(`error_first_pass`).style.display = "block"
            document.getElementsByName('first_pass')[0].classList.add("error")
            return
        }

        if(data.second_pass == ""){
            document.getElementById(`error_second_pass`).style.display = "block"
            document.getElementsByName('second_pass')[0].classList.add("error")
            return
        }

        if(data.first_pass != data.second_pass){
            document.getElementById("error_equality").style.display = "block"
        }

    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value
        
        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
        document.getElementById("error_equality").style.display = "none"
    }

    return(
        <>
            <HeaderClean />
            <div className='prod_portada'>
                <h1>Cambiar Contraseña</h1>
            </div>
            <Form onSubmit={handleSubmit} className="password_reset_container_form">
                <Row className="g-2">
                <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Contraseña *">
                            <Form.Control type="password" placeholder="Contraseña *" name='first_pass' value={data.first_pass} onChange={handleChange} />
                            <small id='error_first_pass' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="floatingInputGrid" label="Confirmar Contraseña *">
                            <Form.Control type="password" placeholder="Confirmar Contraseña *" name='second_pass' value={data.second_pass} onChange={handleChange} />
                            <small id='error_second_pass' className='form_error_input'>Por favor rellene este campo</small>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className='g-2'>
                    <Col md>
                        <small id='error_equality' className='form_error_input'>Las contraseñas no coinciden</small>
                        <Button type="submit">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PasswordReset