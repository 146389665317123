import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logo, userLogo, shoppingCart, logoutLogo, logo_antiguo } from './Images';
import { logout } from '../actions/userActions';
import '../styles/home.css';
import '../styles/header.css';
import { Link } from 'react-router-dom';
import { Form, FormControl, InputGroup } from "react-bootstrap";
import WhatsAppButton from '../screens/WhatsAppButtonScreen';
import NavMobile from './NavMobile';
import SocialButtons from '../screens/SocialButtonsScreen';

let listVisible = false;

function changeIcon() {
  let nav_arrow = document.getElementById("nav_arrow");
  let nav_list_container = document.getElementById("nav_list_container");

  if (!listVisible) {
    nav_list_container.removeAttribute("hidden");
    listVisible = true
    nav_arrow.style.cssText = 'transform: rotate(180deg);';
  } else {
    nav_list_container.setAttribute("hidden", true);
    listVisible = false
    nav_arrow.style.cssText = 'transform: rotate(0deg);';
  }

}

function Header(props_meta) {
  const [existUser, setFlagExistUser] = useState(false)
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  const [navVisible, setNavVisible] = useState(true)

  const userAllLogin = useSelector(state => state.userAllLogin)
  const { userInfoAll } = userAllLogin

  const saved = localStorage.getItem("userInfoAll");
  const initialValue = JSON.parse(saved);

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    window.location.reload()
  }

  useEffect(() => {
    if (saved) {
      setFlagExistUser(true)
    }
  })

  // BUSCADOR
  const [nameProduct, setNameProduct] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    if (nameProduct != "") {
      window.location.href = `/products/search/${nameProduct}`
    }
  }

  //NAVBAR MOBILE
  const toggleNavMobile = () => {
    setNavVisible(!navVisible)
    const navMobileContainer = document.getElementById("nav_mobile_container")
    if (navMobileContainer) {
      if (!navVisible) {
        navMobileContainer.classList.remove("slideIn")
        navMobileContainer.classList.add("slideOut")

      } else {
        navMobileContainer.classList.remove("slideOut")
        navMobileContainer.classList.add("slideIn")
      }
    }
  }

  return (
    <>
      <SocialButtons />
      <WhatsAppButton />
      <div className='space_header'></div>
      <header data-thq="thq-navbar" className='top_header'>
        <div className='slogan_header'>Compras Colectivas Inteligentes. Comprando Unidos, Ahorramos Unidos</div>
        <div className="home-navbar">
          <Link to='/'><img alt="image" src={logo_antiguo} className="home-image-logo" /></Link>
          <div data-thq="thq-navbar-nav" data-role="Nav" className="home-desktop-menu">
            <nav data-thq="thq-navbar-nav-links" data-role="Nav" className="home-nav">
              {
                (existUser) ?
                  <a type="button" onClick={logoutHandler} className="header-title img_force_display"><img src={logoutLogo} className='header_img' /></a> :
                  <Link to="/login" className="header-title img_force_display"><img src={userLogo} className='header_img' /></Link>
              }
              <a className="header-title" href="/#como_funciona">Cómo Funciona</a>
              <Link to="/products" className="header-title">Promos</Link>
              <div className='nav_list' onClick={changeIcon}>
                <div className='text_container'>
                  <span>Trabaja con Nosotros</span>
                  <svg id='nav_arrow' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div id="nav_list_container" className='nav_list_items' hidden>
                  <Link to="/facilitator" className="header-title">Como Gestor Local</Link>
                  <Link to="/supplier" className="header-title">Como Proveedor</Link>
                </div>
              </div>
              {/* <a className="header-title">Equipo</a> */}
              <Link to="/cart" className="header-title img_force_display"><img src={shoppingCart} className='header_img' /></Link>
              {/* <div className='search_desktop'><SearchProduct /></div> */}
              <div className='search_desktop searchProductForm'>
                <Form className="d-flex" onSubmit={handleSubmit}>
                  <InputGroup>
                    <FormControl
                      type="search" value={nameProduct} placeholder="Buscar producto"
                      onChange={(e) => setNameProduct(e.target.value)}
                    />
                    <InputGroup.Text className="bg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                </Form>
              </div>
            </nav>
          </div>
          <div className='subscribe_button_container'>
            <Link className="subscribe_button" to='/register'>Regístrate</Link>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <button className="button home-button04" onClick={toggleNavMobile}>
              <svg viewBox="0 0 1024 1024" className="home-icon14">
                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className='search_mobile searchProductForm'>
          <Form className="d-flex" onSubmit={handleSubmit}>
            <InputGroup>
              <FormControl
                type="search" value={nameProduct} placeholder="Buscar producto"
                onChange={(e) => setNameProduct(e.target.value)}
              />
              <InputGroup.Text className="bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </Form>
        </div>
        <div>
          <NavMobile toggleNavMobile={toggleNavMobile} />
        </div>
      </header>
    </>
  )
}

export default Header
