import axios from 'axios'

import { 
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_LOGIN_REQUEST_INFO,
    USER_LOGIN_SUCCESS_INFO,
    USER_LOGIN_FAIL_INFO,
    USER_LOGOUT_INFO,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST_INFO,
    USER_REGISTER_SUCCESS_INFO,
    USER_REGISTER_FAIL_INFO,
    USER_DETAILS_REQUEST, 
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST_INFO,
    USER_DETAILS_SUCCESS_INFO,
    USER_DETAILS_FAIL_INFO,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS ,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,
    USER_UPDATE_REQUEST_INFO,
    USER_UPDATE_SUCCESS_INFO,
    USER_UPDATE_FAIL_INFO ,
    USER_UPDATE_RESET_INFO,    
    PASSWORD_RESET_SUCCESS, 
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATEADMIN_REQUEST,
    USER_UPDATEADMIN_SUCCESS,
    USER_UPDATEADMIN_FAIL,
    USER_UPDATEADMIN_RESET,
 } from '../constants/userConstants'

 import { ORDER_LIST_MY_RESET } from '../constants/orderConstants'

export const login = (email, password)  => async (dispatch) => {
    try{
        dispatch({
            type:USER_LOGIN_REQUEST
        })


        const url = `https://thor-fast-api.herokuapp.com/users/login?email=${email}&password=${password}`;
        const response = await axios.post(url);                

        dispatch({type:USER_LOGIN_SUCCESS,
                  payload: response.data
                 })
        
        localStorage.setItem('userInfo', JSON.stringify(response.data))        
    }catch(error){
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

export const loginAll = (email) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST_INFO });

        // Recuperar el token del localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo && userInfo.access_token;

        // Configurar los encabezados para incluir el token
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        const { data } = await axios.get(`https://thor-fast-api.herokuapp.com/users/${email}`, config);

        dispatch({
            type: USER_LOGIN_SUCCESS_INFO,
            payload: data
        });

        localStorage.setItem('userInfoAll', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL_INFO,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        });
    }
};

export const logout = () => (dispatch) =>{
    localStorage.removeItem('userInfo')  
    localStorage.removeItem('userInfoAll')  
    dispatch({type:USER_LOGOUT})      
    dispatch({type:USER_LOGOUT_INFO})  
    dispatch({type:ORDER_LIST_MY_RESET})
    dispatch({type:USER_LIST_RESET})
}

// export const register = (name, email, password)  => async (dispatch) => {
//     try{
//         dispatch({
//             type:USER_REGISTER_REQUEST
//         })

//         const config = {
//             headers:{
//                 'Content-type' : 'application/json'
//             }
//         }

//         const {data}  = await axios.post('https://backend.tucolonia.co/api/users/register/',
//             {'name': name, 'email': email, 'password': password},
//             config)

//         dispatch({type:USER_REGISTER_SUCCESS,
//                   payload: data
//                  })
                 
//         dispatch({type:USER_LOGIN_SUCCESS,
//         payload: data
//         })
                   
//         localStorage.setItem('userInfo', JSON.stringify(data))      
//     }catch(error){
//         dispatch({
//             type: USER_REGISTER_FAIL,
//             payload:error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message
//         })
//     }
// }

// export const registerAll = ( name, email, celular, ciudad, barrio, localidad, direccion)  => async (dispatch) => {
//     try{
//         dispatch({
//             type:USER_REGISTER_REQUEST_INFO
//         })

//         const config = {
//             headers:{
//                 'Content-type' : 'application/json'
//             }
//         }

//         const {data}  = await axios.post('https://backend.tucolonia.co/api/users/registerAll/',
//             {'name': name, 'email': email, 'celular': celular, 'ciudad':ciudad, 'barrio':barrio, 'localidad':localidad, 'direccion':direccion},
//             config)

//         dispatch({type:USER_REGISTER_SUCCESS_INFO,
//                   payload: data
//                  })                


//         dispatch({type:USER_LOGIN_SUCCESS_INFO,
//             payload: data
//             })
                   
//          localStorage.setItem('userInfoAllRegister', JSON.stringify(data))      
//     }catch(error){
//         dispatch({
//             type: USER_REGISTER_FAIL_INFO,
//             payload:error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message
//         })
//     }
// }

export const getUserDetails = (id)  => async (dispatch, getState) => {
    try{
        dispatch({
            type:USER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type' : 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data}  = await axios.get(`https://backend.tucolonia.co/api/users/${id}/`,  config)

        dispatch({type:USER_DETAILS_SUCCESS,
                  payload: data
                 })

                 
    }catch(error){
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

// export const getUserDetailsAll = (email)  => async (dispatch) => {
//     try{
//         dispatch({
//             type:USER_DETAILS_REQUEST_INFO
//         }) 
 

//         const {data}  = await axios.get(`https://backend.tucolonia.co/api/usersdetails/${email}/`)

//         dispatch({type:USER_DETAILS_SUCCESS_INFO,
//                   payload: data
//                  })
                 
//     }catch(error){
//         dispatch({
//             type: USER_DETAILS_FAIL_INFO,
//             payload:error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message
//         })
//     }
// }

export const updateUserProfile = (user)  => async (dispatch, getState) => {
    try{
        dispatch({
            type:USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type' : 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data}  = await axios.put(`https://backend.tucolonia.co/api/users/profile/update/`, 
                                         user,        
                                         config)

        dispatch({type:USER_UPDATE_SUCCESS,
                  payload: data
                 })

        dispatch({type:USER_LOGIN_SUCCESS,
                    payload: data
                })
        
        localStorage.setItem('userInfo', JSON.stringify(data))
                 
    }catch(error){
        dispatch({
            type: USER_UPDATE_FAIL,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}


export const updateUserAllProfile = (user, email)  => async (dispatch) => {
    try{
        dispatch({
            type:USER_UPDATE_REQUEST_INFO
        }) 
 

        const {data}  = await axios.put(`https://backend.tucolonia.co/api/usersdetails/update/${email}/`, user )

        dispatch({type:USER_UPDATE_SUCCESS_INFO,
                  payload: data
                 })
        
                 dispatch({type:USER_LOGIN_SUCCESS_INFO,
                    payload: data
                   })
          
          localStorage.setItem('userInfoAll', JSON.stringify([data]))  
                 
    }catch(error){
        dispatch({
            type: USER_UPDATE_FAIL_INFO,
            payload:error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        })
    }
}

//Password Reset https://www.youtube.com/watch?v=ZUjlLN510Fo
// export const reset_password = (email) => async dispatch =>{
//     const config = {
//         headers:{
//             'Content-type' : 'application/x-www-form-urlencoded; charset=UTF-8'            ,

            
//         }
//     }
//     const body = JSON.stringify({email});
//     try{
//         await axios.post(`https://backend.tucolonia.co/api/users/accounts/password_reset/`, body, config)
//         dispatch({
//             type: PASSWORD_RESET_SUCCESS
//         })
//     }catch (err){
//         dispatch({
//             type: PASSWORD_RESET_FAIL
//         })
//     }
// }

// export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch =>{
//     const config = {
//         headers:{
//             'Content-type' : 'application/json'
//         }
//     }
//     const body = JSON.stringify({uid, token, new_password, re_new_password});
//     try{
//         await axios.post(`api/users/accounts/reset/`, body, config)
//         dispatch({
//             type: PASSWORD_RESET_CONFIRM_SUCCESS
//         })
//     }catch (err){
//         dispatch({
//             type: PASSWORD_RESET_CONFIRM_FAIL
//         })
//     }
// }

// export const listUsers = ()  => async (dispatch, getState) => {
//     try{
//         dispatch({
//             type:USER_LIST_REQUEST
//         })

//         const {
//             userLogin: { userInfo },
//         } = getState()

//         const config = {
//             headers:{
//                 'Content-type' : 'application/json',
//                 Authorization: `Bearer ${userInfo.token}`
//             }
//         }

//         const {data}  = await axios.get(`https://backend.tucolonia.co/api/users/`,      
//                                          config)

//         dispatch({type:USER_LIST_SUCCESS,
//                   payload: data
//                  })

                 
//     }catch(error){
//         dispatch({
//             type: USER_LIST_FAIL,
//             payload:error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message
//         })
//     }
// }

// export const deleteUser = (id)  => async (dispatch, getState) => {
//     try{
//         dispatch({
//             type:USER_DELETE_REQUEST
//         })

//         const {
//             userLogin: { userInfo },
//         } = getState()

//         const config = {
//             headers:{
//                 'Content-type' : 'application/json',
//                 Authorization: `Bearer ${userInfo.token}`
//             }
//         }

//         const {data}  = await axios.delete(`https://backend.tucolonia.co/api/users/delete/${id}/`,      
//                                          config)

//         dispatch({type:USER_DELETE_SUCCESS,
//                   payload: data
//                  })

                 
//     }catch(error){
//         dispatch({
//             type: USER_DELETE_FAIL,
//             payload:error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message
//         })
//     }
// }

// export const updateUser = (user) => async (dispatch, getState) => {
//     try {
//         dispatch({
//             type: USER_UPDATEADMIN_REQUEST
//         })

//         const {
//             userLogin: { userInfo },
//         } = getState()

//         const config = {
//             headers: {
//                 'Content-type': 'application/json',
//                 Authorization: `Bearer ${userInfo.token}`
//             }
//         }

//         const { data } = await axios.put(
//             `https://backend.tucolonia.co/api/users/update/${user._id}/`,
//             user,
//             config
//         )

//         dispatch({
//             type: USER_UPDATEADMIN_SUCCESS,
//         })

//         dispatch({
//             type: USER_DETAILS_SUCCESS,
//             payload: data
//         })


//     } catch (error) {
//         dispatch({
//             type: USER_UPDATEADMIN_FAIL,
//             payload: error.response && error.response.data.detail
//                 ? error.response.data.detail
//                 : error.message,
//         })
//     }
// }