import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom';
import Category from '../components/Category'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listCategories, listCategoryDetails } from '../actions/categoryActions'
import { listCategoryProductDetails } from '../actions/productActions'


function CategoryScreen() {
  const match = useParams();
  const dispatchCategories = useDispatch()
  const categoryList = useSelector(state => state.categoryList)
  const {loadingC, errorC,categories} = categoryList

  useEffect(() => {
      dispatchCategories(listCategories())
  },[dispatchCategories] )


  const dispatchCategory = useDispatch()
  const categoryDetails = useSelector(state => state.categoryDetails)
  const {loadingCa, errorCa, category} = categoryDetails
  useEffect(() => {
      dispatchCategory(listCategoryDetails(match.id))
  },[dispatchCategory,match] )

  const dispatchCategoryProd = useDispatch()
  const productCategoryList = useSelector(state => state.productCategoryList)
  const {loading, error, products} = productCategoryList
  useEffect(() => {
    dispatchCategoryProd(listCategoryProductDetails(match.id))
  },[dispatchCategoryProd,match] )

  return (
    <div>      
       {loadingC ? <Loader />
                : errorC ? <Message variant='danger'>{errorC}</Message>
                    :
                    <div>                        
                        {categories.map(categoria =>(        
                            <span>                    
                                <Link to={`/category/${categoria._id}`}>
                                    <Category cate={categoria}/> 
                                </Link> 
                            </span>      
                
                        )            
                        )}                        
                    </div>
            }           
       
        <br></br> 
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            {loadingCa ? <Loader />
                : errorCa ? <Message variant='danger'>{errorCa}</Message>
                    :
                    <h1> Productos en {category.categoria} </h1>
            }   
            
        </div> 

        {loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                    :
                    <Row>            
                          {products.map(product =>(
                              <Col key={product._id} sm={2} md={3} Lg={2} xL={1} >
                                  <Product product={product}/>
                              </Col>
                          )            
                          )}
                  </Row>    
            } 
               
    </div>
  )
}

export default CategoryScreen
