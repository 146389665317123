import React,{useState, useEffect} from 'react'
import { Link, useParams,  useLocation,  useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'

import { register, registerAll, logout, getUserDetails, getUserDetailsAll, updateUserProfile, updateUserAllProfile} from '../actions/userActions'
import {USER_UPDATE_RESET} from '../constants/userConstants'

import { listMyOrders } from '../actions/orderActions'

import validator from 'validator'

function ProfileScreen() {
    const  [name, setName] = useState('')
    const  [email, setEmail] = useState('')
    const  [celular, setCelular] = useState('')
    const  [ciudad, setCiudad] = useState('')
    const  [localidad, setLocalidad] = useState('')
    const  [barrio, setBarrio] = useState('')
    const  [direccion, setDireccion] = useState('')
    const  [password, setPassword] = useState('')
    const  [confirmPassword, setConfirmPassword] = useState('')
    const  [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const validate = (value) => {  
        if (validator.isStrongPassword(value, {
          minLength: 8, minLowercase: 1,
          minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
          setErrorMessage('')
        } else {
          setErrorMessage('No es una contraseña segura. Longitud minima 8, conteniendo al menos  1 número, 1 mayúscula, 1 minuscula y un simbolo.')
        }
      }
      
    const dispatch = useDispatch()
    const dispatchAll = useDispatch()
  
  
    const match = useParams()
    const Location = useLocation()
    const navigate = useNavigate()
  
   
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user} = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo} = userLogin  

    const userUpdateProfile = useSelector(state => state.userLogin)
    const { success } = userUpdateProfile  


    const userAllLogin = useSelector(state => state.userAllLogin)
    const { userInfoAll} = userAllLogin 
  
    const orderListMy = useSelector(state => state.orderListMy)
    const { loading:loadingOrders, error:errorOrders, orders } = orderListMy  
  
    useEffect(() => {
     if(!userInfo){
       navigate('/login')
     }else{
        if(!user || !user.name || success || userInfo._id !== user._id){
            dispatch({type:USER_UPDATE_RESET})
            dispatch(getUserDetails('profile')) 
            dispatch(listMyOrders())          
            //console.log(userInfo.email)
           /*  dispatch(loginAll(email)) */
            //dispatch(getUserDetailsAll(user.email))
        }else{
            setName(user.name)
            setEmail(user.email)
            setCelular(userInfoAll[0].celular) 
            setCiudad(userInfoAll[0].ciudad) 
            setDireccion(userInfoAll[0].direccion) 
            setLocalidad(userInfoAll[0].localidad) 
            setBarrio(userInfoAll[0].barrio) 
        }
      // dispatch(registerAll( name, email, celular, ciudad, barrio, localidad, direccion))      
      // dispatch(logout())
      // window.location.replace('https://registro.tucolonia.co/')
     }
    },[navigate, userInfo,  dispatch, user, success] )
  
/*     useEffect(() => {
        if(userInfoA){
         
         navigate(redirect)
        }
       },[navigate, userInfo, redirect, userInfoA] ) */
  
    const submitHandler = (e) => {
      e.preventDefault()
      if(password != confirmPassword){
        setMessage("Las contraseñas no coinciden")
      }else{
        dispatch(updateUserProfile({
                                   'id':user._id, 
                                   'name': name,
                                   'email':email,
                                   'password': password
                                   }))

        dispatch(updateUserAllProfile({
                                        'id':userInfoAll[0]._id, 
                                        'name': name,
                                        'email':email,
                                        'celular': celular,
                                        'ciudad': ciudad,
                                        'barrio': barrio,
                                        'localidad': localidad,
                                        'direccion': direccion
        }, user.email))
        dispatch({type:USER_UPDATE_RESET})
        dispatch(getUserDetails('profile'))     
        window.location.reload(true);
        setMessage("")
      }      
      
    }


  return (
    <Row>
        <Col md ={3}>
            <h2>Perfil de Usuario</h2>
            {message && <Message variant='danger'> {message} </Message>}
        {error && <Message variant='danger'> {error} </Message>}
        {loading && <Loader />}

      <Form onSubmit={submitHandler}>

        <Form.Group controlId='name'>
            <Form.Label>🧑 Nombre Completo</Form.Label>
            <Form.Control 
                required
                type='name'
                placeholder='Ingresa tu Nombre Completo'    
                value = {name}
                onChange = {(e) => setName(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='email'>
            <Form.Label>📧 E-mail </Form.Label>
            <Form.Control 
                required
                type='email'
                placeholder='Ingresa tu E-mail'    
                value = {email}
                onChange = {(e) => setEmail(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='celular'>
            <Form.Label>📱 Número de celular</Form.Label>
            <Form.Control 
                required
                type= 'number'
                placeholder='Ingresa tu número de celular'    
                value = {celular}
                onChange = {(e) => setCelular(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='ciudad'>
              <Form.Label>🌆 Ciudad</Form.Label>
            <Form.Control 
                required
                type= 'ciudad'
                placeholder='Ingresa tu ciudad'    
                value = {ciudad}
                onChange = {(e) => setCiudad(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='direccion'>
            <Form.Label>🏢 Dirección</Form.Label>
            <Form.Control 
                required
                type= 'direccion'
                placeholder='Ingresa tu direccion'    
                value = {direccion}
                onChange = {(e) => setDireccion(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='localidad'>
            <Form.Label> 🏛 Localidad</Form.Label>
            <Form.Control 
                type= 'localidad'
                placeholder='Ingresa tu localidad'    
                value = {localidad}
                onChange = {(e) => setLocalidad(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='barrio'>
            <Form.Label>🏬 Barrio</Form.Label>
            <Form.Control                 
                type= 'barrio'
                placeholder='Ingresa tu barrio'    
                value = {barrio}
                onChange = {(e) => setBarrio(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        
        <Form.Group controlId='password'>
            <Form.Label>🔏 Contraseña </Form.Label>
            <Form.Control 
                type='password'
                placeholder='Ingresa tu contraseña'    
                value = {password}
                onChange={e => { validate(e.target.value) ; setPassword(e.target.value) }}
            >
            </Form.Control>
        </Form.Group>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{errorMessage}</span>
        <br></br>
        <Form.Group controlId='passwordConfirm'>
            <Form.Label>🔏 Confirmar Contraseña </Form.Label>
            <Form.Control 
                type='password'
                placeholder='Confirma tu contraseña'    
                value = {confirmPassword}
                onChange = {(e) => setConfirmPassword(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        
        <Row className='py-3'>
            <Col>
                Al registrarte aceptas nuestros  <Link to='/terminos'>
                Términos y condiciones📑
                    </Link>  y nuestra <Link to='/politica'>Política de Datos</Link> 
            </Col>
        </Row>
        <Button type='submit' variant='primary'>
            Actualiza tus datos 🐜
        </Button>
      
      </Form>
        </Col>
        <Col md ={9}>
            <h2>Mis Ordenes</h2>
            {loadingOrders ? (
                    <Loader />
                ) : errorOrders ? (
                    <Message variant='danger'>{errorOrders}</Message>
                ) : (
                            <Table striped responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Fecha</th>
                                        <th>Total Orden</th>
                                        <th>Pagada</th>
                                        <th>Entregada</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order._id}>
                                            <td>{order._id}</td>
                                            <td>{order.createdAt.substring(0, 10)}</td>
                                            <td>${order.totalPrice}</td>
                                            <td>{order.isPaid ? order.paidAt.substring(0, 10) : (
                                                <i className='fas fa-times' style={{ color: 'red' }}></i>
                                            )}</td>
                                            <td>
                                                <LinkContainer to={`/order/${order._id}`}>
                                                    <Button className='btn-sm'>Detalles</Button>
                                                </LinkContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
        </Col>
    </Row>
  )
}

export default ProfileScreen
