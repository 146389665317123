import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';

let pageForm = 1

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function SupplierScreen() {
    const [data, setData] = useState({
        "supplier_type": "",
        "supplier_name": "",
        "supplier_identifier": "",
        "supplier_address": "",
        "supplier_name_legal_representative": "",
        "supplier_web_page": "",
        "supplier_city": "",
        "supplier_country": "",
        "supplier_contact_name": "",
        "supplier_contact_position": "",
        "supplier_contact_email": "",
        "supplier_cell_phone_1": "",
        "supplier_cell_phone_2": "",
        "supplier_linkedin": "",
        "supplier_facebook": "",
        "supplier_instagram": "",
        "supplier_youtube": "",
        "supplier_product_service_1": "",
        "supplier_product_service_2": "",
        "supplier_product_service_3": "",
        "supplier_product_service_4": "",
        "supplier_description": "",
        "captcha_text_user": ""
    })
    const inputRequireds = [
        "supplier_type",
        "supplier_name",
        "supplier_identifier",
        "supplier_address",
        "supplier_name_legal_representative",
        "supplier_city",
        "supplier_country",
        "supplier_contact_name",
        "supplier_contact_position",
        "supplier_contact_email",
        "supplier_cell_phone_1",
        "supplier_product_service_1",
        "captcha_text_user"
    ]
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [repLegal, setRepLegal] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [captcha, setCaptcha] = useState(generateString(6))

    const refrescarCaptcha = () => {
        setCaptcha(generateString(6))
    }

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value

        if (inputName == "supplier_type") {
            if (inputValue == 'Persona Juridica') {
                setRepLegal(false)
            } else {
                setRepLegal(true)
                data['supplier_name_legal_representative'] = ''
                if (document.getElementById(`error_supplier_name_legal_representative`)) {
                    document.getElementById(`error_supplier_name_legal_representative`).style.display = "none"
                    document.getElementsByName('supplier_name_legal_representative')[0].classList.remove("error")
                }
            }
        }

        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }

    const validateCaptcha = () => {
        let validation = (captcha === data.captcha_text_user)

        if(!validation){
            document.getElementById(`error_captcha_text_user`).style.display = "block"
            document.getElementById(`error_captcha_text_user`).innerText = "El texto ingresado no coincide con el generado"
            document.getElementsByName('captcha_text_user')[0].classList.add("error")
        }
        
        return validation
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) { return }
        if (!validateCaptcha()) { return }

        let body_form = document.getElementById("body_form")
        let spinner_form = document.getElementById("spinner_form")
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        let dF = data

        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

        const dataForm = {
            "supplier_type": dF.supplier_type,
            "supplier_name": dF.supplier_name,
            "supplier_identifier": dF.supplier_identifier,
            "supplier_country": dF.supplier_country,
            "supplier_city": dF.supplier_city,
            "supplier_address": dF.supplier_address,
            "supplier_cell_phone_1": dF.supplier_cell_phone_1,
            "supplier_cell_phone_2": dF.supplier_cell_phone_2,
            "supplier_linkedin": dF.supplier_linkedin,
            "supplier_facebook": dF.supplier_facebook,
            "supplier_instagram": dF.supplier_instagram,
            "supplier_youtube": dF.supplier_youtube,
            "supplier_web_page": dF.supplier_web_page,
            "supplier_contact_name": dF.supplier_contact_name,
            "supplier_contact_position": dF.supplier_contact_position,
            "supplier_contact_email": dF.supplier_contact_email,
            "supplier_name_legal_representative": dF.supplier_name_legal_representative,
            "supplier_product_service_1": dF.supplier_product_service_1,
            "supplier_product_service_2": dF.supplier_product_service_2,
            "supplier_product_service_3": dF.supplier_product_service_3,
            "supplier_product_service_4": dF.supplier_product_service_4,
            "supplier_description": dF.supplier_description,
            "supplier_bank": "",
            "supplier_account_type": "",
            "supplier_account_number": "",
            "supplier_identification_legal_representative": "",
            "created_at": getCurrentDate()
        }

        axios.post('https://thor-fast-api.herokuapp.com/suppliers/register', dataForm, config).then(res => {
            if (res.status == 200) {
                body_form.style.opacity = "1"
                spinner_form.style.display = "none"
                setShowModal(true)
                return true
            }
        }).catch(error => {
            console.log("Error registrando proveedor: ", error)
            body_form.style.opacity = "1"
            spinner_form.style.display = "none"
            setShowModal(true)
        });
        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validateForm = () => {
        let response = true
        let currentDivStep = document.getElementById(`step_${pageForm}`)

        let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
        if (inputsCurrentStep.length > 0) {
            inputRequireds.forEach((inputName) => {
                let element = document.getElementsByName(inputName)[0]
                let errorSpan = document.getElementById(`error_${inputName}`)
                if (element && errorSpan) {
                    if (
                        data[inputName] == "" && inputsCurrentStep[inputName] &&
                        inputName != "supplier_type" && inputName != "supplier_name_legal_representative") {
                        response = false
                        errorSpan.innerText = "Por favor rellene este campo"
                        errorSpan.style.display = "block"
                        element.classList.add("error")
                    }

                    if (element.getAttribute('type') == "email" && !isValidEmail(data[inputName]) && data[inputName] != "") {
                        response = false
                        errorSpan.innerText = "Por favor ingrese un correo valido"
                        errorSpan.style.display = "block"
                        element.classList.add("error")
                    }
                }
            })
        }

        let selectsCurrentStep = currentDivStep.getElementsByTagName("select")
        if (selectsCurrentStep.length > 0) {
            for (let item of selectsCurrentStep) {
                if (data[item.name] == "" && selectsCurrentStep[item.name]) {
                    response = false
                    document.getElementById(`error_${item.name}`).style.display = "block"
                    document.getElementsByName(item.name)[0].classList.add("error")
                }
                if (item.name == "supplier_type") {
                    if (!repLegal) {
                        if (data[item.name] != '' && data['supplier_name_legal_representative'] == '') {
                            document.getElementById(`error_supplier_name_legal_representative`).style.display = "block"
                            document.getElementsByName('supplier_name_legal_representative')[0].classList.add("error")
                        }

                    }
                }
            }
        }
        return response
    }

    const nextPageForm = (e) => {
        if (!validateForm()) { return }

        let stepFormElements = document.getElementsByClassName("stepForm");
        if (pageForm <= stepFormElements.length) {
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm += 1
            pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
            if (pageForm == stepFormElements.length) {
                e.target.setAttribute("hidden", true)
                document.getElementById("subm_btn").removeAttribute("hidden")
            } else {
                e.target.removeAttribute("hidden")
            }

            if (pageForm > 1) {
                setPrevDisabled(false)
            }
            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    const prevPageForm = (e) => {
        if (pageForm > 0) {
            let stepFormElements = document.getElementsByClassName("stepForm");
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm -= 1
            pageForm = (pageForm == 0) ? 1 : pageForm


            if (pageForm == 1) {
                setPrevDisabled(true)
            } else {
                setPrevDisabled(false)
            }

            if (pageForm < stepFormElements.length) {
                document.getElementById("next_btn").removeAttribute("hidden")
                document.getElementById("subm_btn").setAttribute("hidden", true)
            }

            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h3 className='login_title'>Conviertete en un Proveedor</h3>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>
                    Hola <strong>{data.supplier_name}!</strong><br/>
                    Nos hace muy felices que quieras ser parte de esta Colonia.<br/>
                    En breve nos comunicaremos contigo.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <body className='body_facilitator'>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <div id='body_form'>
                    <div className='breadcrumbs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill active" id='breadcrumb_1' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill" id='breadcrumb_2' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                    <div className='stepForm' id='step_1'>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingSelect" label="Tipo de persona *">
                                    <Form.Select aria-label="Floating label select example" name='supplier_type' value={data.supplier_type} onChange={handleChange} >
                                        <option>- Seleccione -</option>
                                        <option value="Persona Natural">Persona Natural</option>
                                        <option value="Persona Juridica">Persona Juridica</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <small id='error_supplier_type' className='form_error_input'>Por favor rellene este campo</small>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nombre de la empresa/Razon Social/Persona *">
                                    <Form.Control type="text" placeholder="Nombre de la empresa/Razon Social/Persona *" name='supplier_name' value={data.supplier_name} onChange={handleChange} />
                                    <small id='error_supplier_name' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nit / Cedula *">
                                    <Form.Control type="number" placeholder="Nit / Cedula *" name='supplier_identifier' value={data.supplier_identifier} onChange={handleChange} />
                                    <small id='error_supplier_identifier' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Dirección *">
                                    <Form.Control type="text" placeholder="Dirección *" name='supplier_address' value={data.supplier_address} onChange={handleChange} />
                                    <small id='error_supplier_address' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nombre Representante Legal *">
                                    <Form.Control type="text" placeholder="Nombre Representante Legal *" name='supplier_name_legal_representative' value={data.supplier_name_legal_representative} onChange={handleChange} disabled={repLegal} />
                                    <small id='error_supplier_name_legal_representative' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Pagina Web">
                                    <Form.Control type="text" placeholder="Pagina Web" name='supplier_web_page' value={data.supplier_web_page} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Ciudad Sede Principal *">
                                    <Form.Control type="text" placeholder="Ciudad Sede Principal *" name='supplier_city' value={data.supplier_city} onChange={handleChange} />
                                    <small id='error_supplier_city' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="País *">
                                    <Form.Control type="text" placeholder="País *" name='supplier_country' value={data.supplier_country} onChange={handleChange} />
                                    <small id='error_supplier_country' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nombre del Contacto *">
                                    <Form.Control type="text" placeholder="Nombre del Contacto *" name='supplier_contact_name' value={data.supplier_contact_name} onChange={handleChange} />
                                    <small id='error_supplier_contact_name' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cargo del Contacto *">
                                    <Form.Control type="text" placeholder="Cargo del Contacto *" name='supplier_contact_position' value={data.supplier_contact_position} onChange={handleChange} />
                                    <small id='error_supplier_contact_position' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Mail de contacto *">
                                    <Form.Control type="email" placeholder="Mail de contacto *" name='supplier_contact_email' value={data.supplier_contact_email} onChange={handleChange} />
                                    <small id='error_supplier_contact_email' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>
                    <div className='stepForm' id='step_2' hidden>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Celular 1 *">
                                    <Form.Control type="number" placeholder="Celular 1 *" name='supplier_cell_phone_1' value={data.supplier_cell_phone_1} onChange={handleChange} />
                                    <small id='error_supplier_cell_phone_1' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Celular 2">
                                    <Form.Control type="number" placeholder="Celular 2" name='supplier_cell_phone_2' value={data.supplier_cell_phone_2} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de Linkedin">
                                    <Form.Control type="text" placeholder="Cuenta de Linkedin" name='supplier_linkedin' value={data.supplier_linkedin} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de Facebook">
                                    <Form.Control type="text" placeholder="Cuenta de Facebook" min='0' name='supplier_facebook' value={data.supplier_facebook} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de Instagram">
                                    <Form.Control type="text" placeholder="Cuenta de Instagram" name='supplier_instagram' value={data.supplier_instagram} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Cuenta de YouTube">
                                    <Form.Control type="text" placeholder="Cuenta de YouTube" name='supplier_youtube' value={data.supplier_youtube} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2'>
                            <Col md>
                                Principales productos o servicios
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Producto / Servicio 1 *">
                                    <Form.Control type="text" placeholder="Producto / Servicio 1 *" name='supplier_product_service_1' value={data.supplier_product_service_1} onChange={handleChange} />
                                    <small id='error_supplier_product_service_1' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Producto / Servicio 2">
                                    <Form.Control type="text" placeholder="Producto / Servicio 2" name='supplier_product_service_2' value={data.supplier_product_service_2} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Producto / Servicio 3">
                                    <Form.Control type="text" placeholder="Producto / Servicio 3" name='supplier_product_service_3' value={data.supplier_product_service_3} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Producto / Servicio 4">
                                    <Form.Control type="text" placeholder="Producto / Servicio 4" name='supplier_product_service_4' value={data.supplier_product_service_4} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2'>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Si desea agregar comentarios o preguntas, por favor registralas a continuación">
                                    <Form.Control type="textarea" maxLength='55' placeholder="Si desea agregar comentarios o preguntas, por favor registralas a continuación" name='supplier_description' value={data.supplier_description} onChange={handleChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className='g-2 captcha'>
                            <Col md>
                                <label>Captcha</label>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInputGrid">
                                        <Form.Control type="text" className="input_disabled_captcha" value={captcha} disabled/>
                                        <small id='error_facilitator_would_like' className='form_error_input'>Por favor rellene este campo</small>
                                    </FloatingLabel>
                                    <Button title='Refrescar captcha' onClick={refrescarCaptcha}>
                                        <img src={refresh} />
                                    </Button>
                                </InputGroup>
                            </Col>
                            <Col md>
                                <label>Ingresa el texto del captcha *</label>
                                <FloatingLabel controlId="floatingInputGrid" label="Ingresa el texto del captcha *">
                                    <Form.Control type="text" placeholder="Ingresa el texto del captcha *" name='captcha_text_user' value={data.captcha_text_user} onChange={handleChange} />
                                    <small id='error_captcha_text_user' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>

                    <Row className='action_btn_container'>
                        <Button className='prev_btn' id='prev_btn' onClick={prevPageForm} disabled={prevDisabled}>Atrás</Button>
                        <Button className='next_btn' id='next_btn' onClick={nextPageForm} >Siguiente</Button>
                        <Button className='next_btn' id='subm_btn' type="submit" hidden>Enviar</Button>
                    </Row>
                </div>
            </Form>
            </body>
            <Footer />
        </>
    )
}

export default SupplierScreen