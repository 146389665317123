import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';
import { Link } from "react-router-dom";
let pageForm = 1

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function RegisterScreen() {

  const [data, setData] = useState({
    "email": "",
    "username": "",
    "first_name": "",
    "last_name": "",
    "cell_phone": "",
    "city": "",
    "address": "",
    "id_geographical_area": "",
    "password": "",
    "staff_status": false,
    "created_at": getCurrentDate(),
    "captcha_text_user": ""
})
const inputRequireds = [
    "email",
    "first_name",
    "last_name",
    "cell_phone",
    "city",
    "address",
    "password",
    "confirmPassword",
    "id_geographical_area",
    "captcha_text_user"
]
const [prevDisabled, setPrevDisabled] = useState(true)
const [showModal, setShowModal] = useState(false)
const [captcha, setCaptcha] = useState(generateString(6))

const refrescarCaptcha = () => {
  setCaptcha(generateString(6))
}

const handleClose = () => {
  setShowModal(false)
  window.location.href = '/'
}

const handleChange = (e) => {
  let inputName = e.target.name
  let inputValue = e.target.value


  setData({
      ...data, [inputName]: inputValue
  })

  if (document.getElementById(`error_${inputName}`)) {
      document.getElementById(`error_${inputName}`).style.display = "none"
  }
  document.getElementsByName(inputName)[0].classList.remove("error")
}
const validateCaptcha = () => {
  let validation = (captcha === data.captcha_text_user)

  if(!validation){
      document.getElementById(`error_captcha_text_user`).style.display = "block"
      document.getElementById(`error_captcha_text_user`).innerText = "El texto ingresado no coincide con el generado"
      document.getElementsByName('captcha_text_user')[0].classList.add("error")
  }
  
  return validation
}

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) { return }
  if (!validateCaptcha()) { return }

  let body_form = document.getElementById("body_form")
  let spinner_form = document.getElementById("spinner_form")
  let config = {
      headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
      }
  }
  let dF = data

  body_form.style.opacity = ".5"
  spinner_form.style.display = "flex"

  const dataForm = {
      "email": dF.email,
      "username": dF.email,
      "first_name": dF.first_name,
      "last_name": dF.last_name,
      "cell_phone": dF.cell_phone,
      "city": dF.city,
      "address": dF.address,
      "id_geographical_area": 1,
      "password": dF.password,
      "staff_status": false,
      "created_at": getCurrentDate()
  }

  axios.post('https://thor-fast-api.herokuapp.com/users/register', dataForm, config).then(res => {
      if (res.status == 200) {
          body_form.style.opacity = "1"
          spinner_form.style.display = "none"
          setShowModal(true)
          return true
      }
  }).catch(error => {
      console.log("Error registrando usuario: ", error)
      body_form.style.opacity = "1"
      spinner_form.style.display = "none"
      setShowModal(true)
  });
  body_form.style.opacity = ".5"
  spinner_form.style.display = "flex"

}

const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
}

const isValidPhoneNumber = (phoneNumber) => {
  // Eliminar espacios, guiones y otros caracteres no numéricos
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

  // Verificar si el número de teléfono tiene exactamente 10 dígitos
  return cleanedPhoneNumber.length === 10;
};

const isStrongPassword = (password) => {
  // La expresión regular valida si hay al menos 8 caracteres,
  // una letra minúscula, una letra mayúscula y un número en la contraseña.
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password);
};


const validateForm = () => {
  let response = true
  let currentDivStep = document.getElementById(`step_${pageForm}`)

  let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
  if (inputsCurrentStep.length > 0) {
      inputRequireds.forEach((inputName) => {
          let element = document.getElementsByName(inputName)[0]
          let errorSpan = document.getElementById(`error_${inputName}`)
          if (element && errorSpan) {
              if (
                  data[inputName] == "" && inputsCurrentStep[inputName] ) {
                  response = false
                  errorSpan.innerText = "Por favor rellene este campo"
                  errorSpan.style.display = "block"
                  element.classList.add("error")
              }

              if (element.getAttribute('type') == "email" && !isValidEmail(data[inputName]) && data[inputName] != "") {
                  response = false
                  errorSpan.innerText = "Por favor ingrese un correo valido"
                  errorSpan.style.display = "block"
                  element.classList.add("error")
              }
               
              
              if (inputName === "cell_phone" && !isValidPhoneNumber(data[inputName]) && data[inputName] !== "") {
                response = false;
                errorSpan.innerText = "Por favor ingrese un número de celular válido";
                errorSpan.style.display = "block";
                element.classList.add("error");
              }
              
              if (inputName === "password") {
                if (data[inputName] === "") {
                  response = false;
                  errorSpan.innerText = "Por favor rellene este campo";
                  errorSpan.style.display = "block";
                  element.classList.add("error");
                } else if (!isStrongPassword(data[inputName])) {
                  response = false;
                  errorSpan.innerText =
                    "La contraseña debe tener al menos 8 caracteres y contener una minúscula, una mayúscula y un número";
                  errorSpan.style.display = "block";
                  element.classList.add("error");
                }
              }
               // Validate confirmPassword field
              if (inputName === "confirmPassword" && data[inputName] !== data.password) {
                response = false;
                errorSpan.innerText = "Las contraseñas no coinciden";
                errorSpan.style.display = "block";
                element.classList.add("error");
              }
          }
      })
  }

  let selectsCurrentStep = currentDivStep.getElementsByTagName("select")
  if (selectsCurrentStep.length > 0) {
      for (let item of selectsCurrentStep) {
          if (data[item.name] == "" && selectsCurrentStep[item.name]) {
              response = false
              document.getElementById(`error_${item.name}`).style.display = "block"
              document.getElementsByName(item.name)[0].classList.add("error")
          }          
      }
  }
  return response
}

const nextPageForm = (e) => {
  if (!validateForm()) { return }

  let stepFormElements = document.getElementsByClassName("stepForm");
  if (pageForm <= stepFormElements.length) {
      for (let i = 0; i < stepFormElements.length; i++) {
          stepFormElements[i].setAttribute("hidden", true)
      }

      document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
      pageForm += 1
      pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
      if (pageForm == stepFormElements.length) {
          e.target.setAttribute("hidden", true)
          document.getElementById("subm_btn").removeAttribute("hidden")
      } else {
          e.target.removeAttribute("hidden")
      }

      if (pageForm > 1) {
          setPrevDisabled(false)
      }
      document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
      document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
  }
}

const prevPageForm = (e) => {
  if (pageForm > 0) {
      let stepFormElements = document.getElementsByClassName("stepForm");
      for (let i = 0; i < stepFormElements.length; i++) {
          stepFormElements[i].setAttribute("hidden", true)
      }

      document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
      pageForm -= 1
      pageForm = (pageForm == 0) ? 1 : pageForm


      if (pageForm == 1) {
          setPrevDisabled(true)
      } else {
          setPrevDisabled(false)
      }

      if (pageForm < stepFormElements.length) {
          document.getElementById("next_btn").removeAttribute("hidden")
          document.getElementById("subm_btn").setAttribute("hidden", true)
      }

      document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
      document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
  }
}
 
  return (
    <>
            <Header />
            <div className='prod_portada'>
                <h3 className='login_title'>Estás a un paso de pertenercer a la mayor red de compras colectivas de Colombia. Registrate</h3>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>
                    Hola <strong>{data.supplier_name}!</strong><br/>
                    Nos hace muy felices que quieras ser parte de esta Colonia.<br/>
                    En breve nos comunicaremos contigo.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <body className='body_facilitator'>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <div id='body_form' >
                    <div className='breadcrumbs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill active" id='breadcrumb_1' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill" id='breadcrumb_2' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                    <div className='stepForm' id='step_1'>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🧑 Nombre(s)*">
                                    <Form.Control type="text" placeholder="Nombres *" name='first_name' value={data.first_name} onChange={handleChange} />
                                    <small id='error_first_name' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🧑 Apellido(s) *">
                                    <Form.Control type="text" placeholder="🧑 Apellidos *" name='last_name' value={data.last_name} onChange={handleChange} />
                                    <small id='error_last_name' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="📱 Celular *">
                                    <Form.Control type="number" placeholder="Celular 1 *" name='cell_phone' value={data.cell_phone} onChange={handleChange} />
                                    <small id='error_cell_phone' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="📧 Correo Electrónico *">
                                    <Form.Control type="email" placeholder="Correo Electrónico *" name='email' value={data.email} onChange={handleChange} />
                                    <small id='error_email' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🌆 Ciudad *">
                                    <Form.Control type="text" placeholder="Ciudad *" name='city' value={data.city} onChange={handleChange} />
                                    <small id='error_city' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🏢 Dirección *">
                                    <Form.Control type="text" placeholder="Dirección *" name='address' value={data.address} onChange={handleChange} />
                                    <small id='error_address' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🔏 Contraseña *">
                                    <Form.Control type="password" placeholder="🔏 Contraseña" name='password' value={data.password} onChange={handleChange} />
                                    <small id='error_password' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🔏 Confirmar Contraseña">
                                    <Form.Control type="password" placeholder="Confirmar Contraseña *" name='confirmPassword'  onChange={handleChange} />
                                    <small id='error_confirmPassword' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>
                    <div className='stepForm' id='step_2' hidden>                        
                        <Row className='g-2 captcha'>
                            <Col md>
                                <label>Captcha</label>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInputGrid">
                                        <Form.Control type="text" className="input_disabled_captcha" value={captcha} disabled/>
                                        <small id='error_facilitator_would_like' className='form_error_input'>Por favor rellene este campo</small>
                                    </FloatingLabel>
                                    <Button title='Refrescar captcha' onClick={refrescarCaptcha}>
                                        <img src={refresh} />
                                    </Button>
                                </InputGroup>
                            </Col>
                            <Col md>
                                <label>Ingresa el texto del captcha *</label>
                                <FloatingLabel controlId="floatingInputGrid" label="Ingresa el texto del captcha *">
                                    <Form.Control type="text" placeholder="Ingresa el texto del captcha *" name='captcha_text_user' value={data.captcha_text_user} onChange={handleChange} />
                                    <small id='error_captcha_text_user' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </div>

                    <Row className='action_btn_container'>
                        <Button className='prev_btn' id='prev_btn' onClick={prevPageForm} disabled={prevDisabled}>Atrás</Button>
                        <Button className='next_btn' id='next_btn' onClick={nextPageForm} >Siguiente</Button>
                        <Button className='next_btn' id='subm_btn' type="submit" hidden>Enviar</Button>
                    </Row>
                    * Al registrarte aceptas nuestros 
                    <Link className='link_register' to='/terminos'> Términos y condiciones</Link> y nuestra 
                    <Link className='link_register' to='/politica'> Política de Datos</Link>
                </div>
            </Form>
            </body>
            
            <Footer />
        </>
    // <div>
    //   <Header />
    //     <FormContainer>
    //     {message && <Message variant='danger'> {message} </Message>}
    //     {error && <Message variant='danger'> {error} </Message>}
    //     {loading && <Loader />}
    //     <Form onSubmit={submitHandler} className='register_form'>
    //       <Row>
    //         <div className='login_title'>Estás a un paso de pertenercer a la mayor red de compras colectivas de Colombia. Registrate</div>
    //       </Row>
    //       <Form.Group controlId='name'>
    //         <Form.Label>🧑 Nombre Completo</Form.Label>
    //         <Form.Control required type='name' placeholder='Ingresa tu Nombre Completo' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='email'>
    //         <Form.Label>📧 E-mail </Form.Label>
    //         <Form.Control required type='email' placeholder='Ingresa tu E-mail' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='celular'>
    //         <Form.Label>📱 Número de celular</Form.Label>
    //         <Form.Control required type='number' placeholder='Ingresa tu número de celular' value={celular} onChange={(e) => setCelular(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='ciudad'>
    //         <Form.Label>🌆 Ciudad</Form.Label>
    //         <Form.Control required type='ciudad' placeholder='Ingresa tu ciudad' value={ciudad} onChange={(e) => setCiudad(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='direccion'>
    //         <Form.Label>🏢 Dirección</Form.Label>
    //         <Form.Control required type='direccion' placeholder='Ingresa tu direccion' value={direccion} onChange={(e) => setDireccion(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='localidad'>
    //         <Form.Label> 🏛 Localidad</Form.Label>
    //         <Form.Control type='localidad' placeholder='Ingresa tu localidad' value={localidad} onChange={(e) => setLocalidad(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='barrio'>
    //         <Form.Label>🏬 Barrio</Form.Label>
    //         <Form.Control type='barrio' placeholder='Ingresa tu barrio' value={barrio} onChange={(e) => setBarrio(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Form.Group controlId='password'>
    //         <Form.Label>🔏 Contraseña </Form.Label>
    //         <Form.Control required type='password' placeholder='Ingresa tu contraseña' value={password} onChange={e => { validate(e.target.value); setPassword(e.target.value) }}></Form.Control>
    //       </Form.Group>
    //       <span style={{ fontWeight: 'bold', color: 'red', }}>{errorMessage}</span>
    //       <Form.Group controlId='passwordConfirm'>
    //         <Form.Label>🔏 Confirmar Contraseña </Form.Label>
    //         <Form.Control required type='password' placeholder='Confirma tu contraseña' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
    //       </Form.Group>
    //       <Row className='py-3'>
            // <Col className='black_text'>
            //   Al registrarte aceptas nuestros 
            //   <Link className='link_register' to='/terminos'>Términos y condiciones</Link> y nuestra 
            //   <Link className='link_register' to='/politica'>Política de Datos</Link>
            // </Col>
    //       </Row>
    //       <Button className='register_button' type='submit' variant='primary'>Registrate en TuColonia.co 🐜</Button>
    //       <Link className='login_redirect_button' to='/login'>Ya tengo una cuenta</Link>
    //     </Form>

    //   </FormContainer>
    //   <Footer />
    // </div>
  )
}

export default RegisterScreen
