import React from 'react'
import { logo_antiguo, faceLogo, instaLogo, ytLogo } from './Images'
import '../styles/footer.css'
import { linkInstagram, linkFB, linkTwitter, linkYT } from '../constants/socialConstants'

function Footer() {
  return (
    <div className='footer'>
      <img className='footer_logo' src={logo_antiguo} />
      <div className='text_container'>
        <a href='/#como_funciona'>Cómo Funciona</a>
        <a href='/products'>Promos</a>
        <a href="/facilitator">Trabaja Como Gestor Local</a>
        <a href="/supplier">Trabaja Como Proveedor</a>
      </div>
      <div className='social'>
        <div className='text'>@ 2023 Tu Colonia, todos los derechos reservados.</div>
        {/* <div className='social_img_container'>
          <a href={linkTwitter} target='_blank'><img src={twitterLogo} /></a>
          <a href={linkInstagram} target='_blank'><img src={instaLogo} /></a>
          <a href={linkFB} target='_blank'><img src={faceLogo} /></a>
          <a href={linkYT} target='_blank'><img src={ytLogo} /></a>
        </div> */}
      </div>
    </div>
  )
}

export default Footer
