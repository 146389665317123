import React from "react";
import '../styles/collage.css';
import { Link } from "react-router-dom";
import { linkCreaTuColonia } from "../constants/socialConstants";

function Collage() {
    return (
        <section className='collage'>
            <div className='collage_container'>
                <div className='gallery'>
                    {/* hogar */}
                    <Link to='/pintahogar' className='hogar'>
                        <div className='hogar_container subcontainer'>
                            <h1>Hogar - Promo Pinta tu Casa</h1>
                            <span>Descuentos hasta del 30%</span>
                            <ul>
                                <li>Pinta, renueva tu Casa o Apartamento.</li>
                            </ul>
                            <button className="button_collage">Ir a  promo</button>
                        </div>
                        <div className="collage_more_info">
                            <p>
                                Pide individualmente que te pinten tu hogar, unimos a varias personas que quieren lo mismo y Listo!!!  Todos obtienen un gran descuento.
                            </p>
                            <p>
                                Preinscríbete antes del 30 de Noviembre, te llamamos a confirmar cotización y si al menos 3 personas piden el servicio, obtienes descuento de 14%  (Válido en norte de Bogotá, Chía, Cajicá, Zipa, Tocancipá, La Calera)
                            </p>
                        </div>
                    </Link>
                    {/* conocenos */}
                    <a href="#como_funciona" className='conocenos'>
                        <div className='conocenos_container subcontainer'>
                            <div>
                                <h1>Conócenos</h1>
                                <ul>
                                    <li>Compras</li>
                                    <li>Colectivas</li>
                                    <li>Inteligentes</li>
                                </ul>
                            </div>
                            <div>
                                <button className="button_collage conocenos_button">Conócenos</button>
                            </div>
                        </div>
                    </a>
                    {/* servicios financieros */}
                    <Link to='/gasolina' className='serv_finan'>
                        <div className='serv_fin_container subcontainer'>
                            <div>
                                <h1>Ahorra hasta 300 por galón</h1>
                                <ul>
                                    <li>Viajar ahora es más barato</li>
                                    <li>Inscribete fácilmente</li>
                                </ul>
                            </div>
                            <div>
                                <button className="button_collage">Ir a  promo</button>
                            </div>
                        </div>
                        <div className="collage_more_info">
                            <p>
                                ¡Si eres parte de Tu Colonia viajar es más barato! Ahorra hasta $300 por galón en bombas aliadas. 
                                (Descuento exclusivo de Tu Colonia)
                            </p>
                        </div>
                    </Link>
                    {/* salud */}
                    <Link to='/register' className='salud'>
                        <div className='salud_container subcontainer'>
                            <h1>Salud</h1>
                            <ul>
                                <li>Planes de Salud</li>
                                <li>Medicinas</li>
                                <li>Odontología</li>
                                <li>Óptica y más</li>
                            </ul>
                            <button className="button_collage">Ir a  promo</button>
                        </div>
                        <div className="collage_more_info">
                            <p>
                                Pronto obtendrás descuentos por Compas Colectivas Inteligentes en:
                            </p>
                            <ul>
                                <li>Planes de Salud</li>
                                <li>Medicinas</li>
                                <li>Odontología</li>
                                <li>Óptica y más</li>
                            </ul>
                        </div>
                    </Link>
                    {/* perrito */}
                    <Link to='/products/4' className='promo'>
                        <div className='promo_container subcontainer'>
                            <h1>Aprovecha antes del 30 de Octubre</h1>
                            <span>Hasta 30% de Descuento.</span>
                            <button className="button_collage">Ir a  promo</button>
                        </div>
                        <div className="collage_more_info">
                            <p>Alimentos para tu mascota, Arena, Medicamentos OTC, lociones y mas!!!</p>
                        </div>
                    </Link>
                    {/* tu colonia */}
                    <a className='tu_colonia' target="_blank" href={linkCreaTuColonia}>
                        <div className='tu_colonia_container subcontainer'>
                            <h1>Crea Tu Colonia</h1>
                            <button className="button_collage">Aprende cómo →</button>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Collage;