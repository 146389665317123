import React, { useEffect } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { formatCurrency } from "react-native-format-currency"
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/cart.css';

function CartScreen() {
    const match = useParams()
    const Location = useLocation()
    const navigate = useNavigate();
    const productId = match.id
    const qty = Location.search ? Number(Location.search.split('=')[1]) : 1

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart
    useEffect(() => {
        if (productId) {
            dispatch(addToCart(productId, qty))
        }
    }, [dispatch, productId, qty])

    const removeFromCartHandler = (productId) => {
        dispatch(removeFromCart(productId))
    }

    const checkoutHandler = () => {
        navigate('/login?redirect=shipping')
    }

    const seguirPidiendo = () => {
        navigate('/')
    }

    return (
        <div>
            <Header />
            <div className='carrito_portada'>
                <h1>Carro de compras</h1>
            </div>
            <div className='carrito_container'>
                <div>{
                    cartItems.length === 0 ? (
                        <div className='empty_message'>
                            <p>Tu carro esta vacío. <Link to='/products/'>Volver a la tienda</Link></p>
                        </div>
                    ) : (
                        <div className='list_car_items'>
                            <div className='list_cart_prod'>
                                {cartItems.map(item => (
                                    <div key={item.product} className='cart_prod_container'>
                                        <div className='cart_prod_image_container'>
                                            <img src={item.image} alt={item.name} />
                                        </div>
                                        <div className='cart_prod_desc_container'>
                                            <Link to={`/product/${item.product}`}>{item.name}</Link>
                                            <p>
                                                <strong>Precio Actual:</strong>
                                                {formatCurrency({ amount: parseInt(item.price), code: "COP" })[0]}
                                            </p>
                                            <p>
                                                Faltan <strong>{item.compradoresFaltantes} </strong> 🐜 para que disminuya a
                                                <strong> {formatCurrency({ amount: parseInt(item.nextPrice), code: "COP" })[0]} </strong>
                                            </p>
                                            <div className='quantity_container'>
                                                <div className='num_container'>
                                                    <button className='num_btn_l' onClick={() => dispatch(addToCart(item.product, Number(item.qty - 1)))}>-</button>
                                                    <span>{item.qty}</span>
                                                    <button className='num_btn_r' onClick={() => dispatch(addToCart(item.product, Number(item.qty + 1)))}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart_prod_action_container'>
                                            <Button type='button' onClick={() => removeFromCartHandler(item.product)}><i className='fas fa-trash'></i></Button>
                                            <Button type='button' className='btn-block' disabled={cartItems.length === 0} onClick={seguirPidiendo}><i className='fas fa-eye'></i></Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {cartItems.length === 0 ? null :
                                <div className='total_card'>
                                    <p>Subtotal: <span>{formatCurrency({ amount: cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(0), code: "COP" })[0]}</span></p>
                                    <Button type='button' className='btn-block' disabled={cartItems.length === 0} onClick={checkoutHandler}>
                                        Confirmar Pedido
                                    </Button>
                                </div>
                            }
                        </div>
                    )
                }</div>
                <div className='seguir_pidiendo_container'>
                    {
                        cartItems.length === 0 ? null :
                            <a href='/products/' className='btn-block' disabled={cartItems.length === 0} onClick={seguirPidiendo}                    >
                                <i className='fas fa-eye'></i> Ver más productos
                            </a>
                    }
                </div>
            </div>
            
            <Footer />
        </div>

    )
}

export default CartScreen
